import { useState, useEffect, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import { getCoralFromAPI, getCuratedCoralFromAPI, createCoralFromAPI, createCoralWithArtistFromAPI, getArtistDetails, saveCoralToAPI, saveTemporaryCoralToAPI, simulateCoral, simulateCoralWithoutAuth, updateCoralSharingSettings, createCoralSubscription as createCoralSubscription, pauseCoralSubscription, cancelCoralSubscription, updateCoralCuratedSettings, reactivateCoralSubscription } from '../api/api'; //, updateCoralShare, updateCoralFindable } from '../api/api';
import useCoralEditorArtistManagement from './useCoralEditorArtistManagement';
import { ClientCoral, CoralType, CoralSimulation, CoralSimulationResult } from 'shared/types/platformTypes';
import { CoralSimulator } from 'shared/utils/coralSimulator';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../components/AuthContext/AuthContext';

const useCoralEditor = (initialCoralId: string, isShared = false, artistId?: string, apiClient = { getCoralFromAPI, getCuratedCoralFromAPI, createCoralFromAPI, createCoralWithArtistFromAPI, getArtistDetails, saveCoralToAPI, saveTemporaryCoralToAPI, simulateCoral, simulateCoralWithoutAuth, updateCoralSharingSettings, createCoralSubscription: createCoralSubscription, pauseCoralSubscription, cancelCoralSubscription, updateCoralCuratedSettings, reactivateCoralSubscription }) => {
    const { userProfile } = useAuthContext();
    const [coralData, setCoralData] = useState<ClientCoral | null>(null);
    const [updatedPledgeAmount] = useState<number | null>(null);
    const [simulationResults, setSimulationResults] = useState<CoralSimulation>({
        transactionFee: 0,
        platformFee: 0,
        artistAllocations: [],
    });
    // New state to store active filters
    const [activeFilters, setActiveFilters] = useState<Array<{ filterType: 'genre' | 'location'; value: string }>>([]);
    const safeSetCoralData: (updateFunction: (prevData: ClientCoral) => ClientCoral) => void = updateFunction => {
        setCoralData(prevData => {
            if (prevData === null) {
                // Handle the null case
                return null;
            }

            // Otherwise, update the ClientCoral object
            return updateFunction(prevData);
        });
    };

    const { addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid, addArtistFromDynamicArtistPool, retrieveArtistsFromCoral, isDynamicArtistPools, uniqueGenres, uniqueLocations, dynamicArtistPools, sourceArtistPoolGuids } = useCoralEditorArtistManagement(coralData, activeFilters, safeSetCoralData);

    // Log updated coral to the console
    useEffect(() => {
        console.log('Updated coral:', coralData);
    }, [coralData]);

    // Fetch initial coral data
    useEffect(() => {
        const fetchCoralData = async () => {
            console.log('useEffect initialCoralId:', initialCoralId);
            let data: ClientCoral;
            if (initialCoralId !== '') {
                data = isShared ? await apiClient.getCuratedCoralFromAPI(initialCoralId) as ClientCoral : await apiClient.getCoralFromAPI(initialCoralId) as ClientCoral;
            } else if (artistId) {
                data = await apiClient.createCoralWithArtistFromAPI(artistId) as ClientCoral;
            } else {
                data = await apiClient.createCoralFromAPI(CoralType.USER) as ClientCoral;
            }
            setCoralData(data);
        };

        fetchCoralData();
    }, [initialCoralId, artistId]);



    // Effect to update activeFilters when coralData changes
    useEffect(() => {
        // Function to extract active filters from coralData
        const extractActiveFilters = (coral: ClientCoral | null): Array<{ filterType: 'genre' | 'location'; value: string }> => {
            const filters: Array<{ filterType: 'genre' | 'location'; value: string }> = [];
            coral?.coralRuleSet.layers.forEach(layer => {
                if (layer.layerType === 'userFiltering' && layer.filters) {
                    layer.filters.forEach(filter => {
                        Object.entries(filter.parameters).forEach(([key, value]) => {
                            if (key === 'genre' || key === 'location') {
                                filters.push({ filterType: key, value });
                            }
                        });
                    });
                }
            });
            return filters;
        };

        // Set active filters based on coralData
        if (coralData) {
            setActiveFilters(extractActiveFilters(coralData));
        }
    }, [coralData]);

    const updateFilter = (filterType: 'genre' | 'location', value: string, action: 'add' | 'remove') => {
        if (coralData) {
            // Find the userFiltering layer
            const userFilteringLayer = coralData.coralRuleSet.layers.find(layer => layer.layerType === 'userFiltering');

            if (userFilteringLayer && userFilteringLayer.filters) {
                // If action is 'add', add the filter
                if (action === 'add') {
                    userFilteringLayer.filters.push({ filterType, parameters: { [filterType]: value } });
                }
                // If action is 'remove', remove the filter
                else if (action === 'remove') {
                    userFilteringLayer.filters = userFilteringLayer.filters.filter(filter => filter.parameters[filterType] !== value);
                }

                // Update the coralData state
                setCoralData({
                    ...coralData,
                    coralRuleSet: {
                        ...coralData.coralRuleSet,
                        layers: coralData.coralRuleSet.layers.map(layer =>
                            layer.layerType === 'userFiltering' ? userFilteringLayer : layer
                        ),
                    },
                });
            }
        }
    };

    const updatePledgeAmount = useCallback((newPledgeAmount: number) => {
        setCoralData(prevData => {
            if (!prevData) return null;
            return {
                ...prevData,
                pledgeAmount: newPledgeAmount,
            };
        });
    }, []);

    const debouncedUpdatePledgeAmount = useMemo(
        () => debounce(updatePledgeAmount, 300),
        [updatePledgeAmount]
    );

    const updateCoralName = (newCoralName: string) => {
        if (coralData) {
            setCoralData({
                ...coralData,
                coralName: newCoralName,
            });
        }
    };

    const addPinnedArtist = (artistId: string) => {
        if (coralData) {
            setCoralData({
                ...coralData,
                pinnedArtists: [...coralData.pinnedArtists, artistId],
            });
        }
    };

    const removePinnedArtist = (artistId: string) => {
        if (coralData) {
            setCoralData({
                ...coralData,
                pinnedArtists: coralData.pinnedArtists.filter(id => id !== artistId),
            });
        }
    };

    const toggleShare = async () => {
        if (coralData) {
            const newVisibilityStatus = !coralData.coralShared;
            try {
                const response = await apiClient.updateCoralSharingSettings(coralData.guid, newVisibilityStatus);
                console.log(response);
                setCoralData({ ...coralData, sharedCoralGuid: response.sharedCoralGuid, coralShared: response.isShared, uniqueUrl: response.url });
            } catch (error) {
                console.error('Failed to update coral visibility:', error);
            }
        }
    };

    const toggleCurated = async () => {
        if (coralData) {
            const newCuratedStatus = !coralData.coralCurated;
            try {
                const response = await apiClient.updateCoralCuratedSettings(coralData.guid, newCuratedStatus);
                setCoralData({ ...coralData, coralCurated: response.isCurated });
            } catch (error) {
                console.error('Failed to update coral curated status:', error);
            }
        }
    };

    const toggleFindable = async () => {
        console.log("toggleFindable method called");
        // if (coralData) {
        //   const newFindableStatus = !coralData.findable;
        //   await apiClient.updateCoralFindable(coralData.guid, newFindableStatus);
        //   setCoralData({ ...coralData, findable: newFindableStatus });
        // }
    };

    // Function to save the coral
    const saveCoral = async () => {
        let guid = null;
        if (coralData) {
            if (userProfile) {
                const response = await apiClient.saveCoralToAPI(coralData);
                guid = response.guid;
            }
            else {
                const response = await apiClient.saveTemporaryCoralToAPI(coralData);
                guid = response.tempCoralId;
            }
            // Update the coralData state with the new guid
            setCoralData({ ...coralData, guid: guid });
        }
        return guid;
    };

    // Function to simulate the coral
    const simulateCoral = useCallback(() => {
        if (coralData) {
            const coralSimulator = new CoralSimulator(coralData);
            const response = coralSimulator.process();

            const updatedArtistAllocations: CoralSimulationResult[] = response.artistAllocations.map(artist => ({
                artist_id: artist.artist_id,
                artist_name: artist.artist_name,
                spotify_popularity: artist.spotify_popularity,
                included: artist.included,
                reasons: artist.reasons,
                allocation_percentage: artist.allocation_percentage || 0,
                allocated_amount: artist.allocated_amount || 0,
                impact_100_users: Math.round((artist.allocated_amount || 0) * 100),
                impact_1000_users: Math.round((artist.allocated_amount || 0) * 1000),
                impact_5000_users: Math.round((artist.allocated_amount || 0) * 5000)
            }));

            const newSimulationResults = {
                transactionFee: response.transactionFee,
                platformFee: response.platformFee,
                artistAllocations: updatedArtistAllocations
            };

            setSimulationResults(prevResults =>
                isEqual(prevResults, newSimulationResults) ? prevResults : newSimulationResults
            );
        }
    }, [coralData]);

    const debouncedSimulateCoral = useMemo(
        () => debounce(simulateCoral, 300),
        [simulateCoral]
    );

    const createCoralSubscription = async (subscriptionId: string) => {
        if (coralData) {
            try {
                const subscriptionResponse = await apiClient.createCoralSubscription(coralData.guid, subscriptionId);
                trackEvent(EventName.CREATE_CORAL_COMPLETED, {
                    userId: userProfile!.userId,
                    coralId: coralData.guid,
                    amount: coralData!.pledgeAmount,
                    artistCount: simulationResults.artistAllocations.length
                });
                return subscriptionResponse;

            } catch (error) {
                console.error('Failed to set coral subscription to pending:', error);
            }
        }
    };

    // Function to pause the coral
    const pauseCoralSubscription = async () => {
        if (coralData) {
            try {
                return await apiClient.pauseCoralSubscription(coralData.guid);
            } catch (error) {
                console.error('Failed to pause coral subscription:', error);
            }
        }
    }

    const cancelCoralSubscription = async () => {
        if (coralData) {
            try {
                await apiClient.cancelCoralSubscription(coralData.baseGuid);
            } catch (error) {
                console.error('Failed to cancel coral subscription:', error);
            }
        }
    };

    const reactivateSubscription = async () => {
        if (coralData) {
            try {
                await apiClient.reactivateCoralSubscription(coralData.guid);
            } catch (error) {
                console.error('Failed to reactivate coral subscription:', error);
            }
        }
    };

    //   const coralDataRef = useRef(coralData);
    //   coralDataRef.current = coralData;

    return {
        coralData, updatedPledgeAmount, addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid,
        addArtistFromDynamicArtistPool, addPinnedArtist, removePinnedArtist, retrieveArtistsFromCoral, sourceArtistPoolGuids,
        isDynamicArtistPools, uniqueGenres, uniqueLocations, dynamicArtistPools, activeFilters,
        updateCoralName, saveCoral, createCoralSubscription: createCoralSubscription, pauseCoralSubscription, cancelCoralSubscription, simulationResults, updateFilter, toggleShare,
        toggleCurated, toggleFindable, reactivateSubscription,
        updatePledgeAmount: debouncedUpdatePledgeAmount,
        simulateCoral: debouncedSimulateCoral,
    };
};

export default useCoralEditor;

