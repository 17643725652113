import { ArtistApplication, ArtistProcessingQueueRecord, UserIdentityVerification } from 'shared/types/platformTypes';
import { fetchFromAPI } from './api';


export async function getMissingArtists(): Promise<ArtistProcessingQueueRecord[]> {
    const endpoint = `/artist/missing`;
    return fetchFromAPI<ArtistProcessingQueueRecord[]>(endpoint, (data) => data as ArtistProcessingQueueRecord[]);
}

export async function retryMissingArtist(artistName?: string, spotifyUrl?: string): Promise<void> {
    const endpoint = `/artist/retry`;
    const body: { artistName?: string, spotifyUrl?: string } = {};
    if (artistName) body.artistName = artistName;
    if (spotifyUrl) body.spotifyUrl = spotifyUrl;
    return fetchFromAPI<void>(endpoint, (data) => data, 'POST', JSON.stringify(body));
}

export const getUserIdentityVerifications = async (): Promise<UserIdentityVerification[]> => {
    return fetchFromAPI<UserIdentityVerification[]>(
      '/user/verification/all',
      (data) => data as UserIdentityVerification[],
      'GET'
    );
  };
  
  export const processIdentityVerification = async (verificationId: string, status: 'approved' | 'rejected'): Promise<void> => {
    const endpoint = `/user/verification/process`;
    const body = JSON.stringify({ verificationId, status });
    return fetchFromAPI<void>(endpoint, (data) => data, 'POST', body);
  };
  
  export const getArtistApplications = async (): Promise<ArtistApplication[]> => {
    return fetchFromAPI<ArtistApplication[]>(
      '/applications/all',
      (data) => data as ArtistApplication[],
      'GET'
    );
  };
  
  export const processArtistApplication = async (applicationId: string, status: 'APPROVED' | 'REJECTED', verifiedArtistId: string): Promise<void> => {
    const endpoint = `/application/process`;
    const body = JSON.stringify({ applicationId, status, verifiedArtistId });
    return fetchFromAPI<void>(endpoint, (data) => data, 'POST', body);
  };
