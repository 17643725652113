import React, { useState, useEffect, useRef } from 'react';
import '@aws-amplify/ui-react/styles.css';
import mainBackgroundImage from '../../assets/images/coral-reef-black.svg';
import blueWaveImage from '../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../assets/images/dark-blue-wave.png';
import FAQItem from '../../components/FAQItem/faqItem';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { faqData } from './faqForArtists';
import CollectiveImpactTable from '../../components/CollectiveImpactTable/CollectiveImpactTable';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import RecentCoralVisualisation from '../../components/RecentCoralVisualisation/RecentCoralVisualisation';
import CoralEcosystem from '../../components/CoralEcosystem/CoralEcosystem';
import ArtistPayoutChecker from '../../components/ArtistPayoutChecker/ArtistPayoutChecker';
// import { Tooltip } from 'react-tooltip';
import heroBackgroundImageGuitar from '../../assets/images/hero-image-man-guitar.jpg';
import heroBackgroundImageHipHop from '../../assets/images/hero-image-mc.jpg';
import ArtistProfileTools from '../../components/ArtistProfileTools/ArtistProfileTools';
import CoralButton from '../../components/CoralButtons/CoralButton';

const ForArtists = () => {
    const [activeImage, setActiveImage] = useState(0);
    const artistProfileToolsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        trackEvent(EventName.FOR_ARTIST_PAGE_VIEW, {});
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveImage((prev) => (prev === 0 ? 1 : 0));
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const scrollToArtistProfileTools = () => {
        console.log("Scroll function called");
        if (artistProfileToolsRef.current) {
            console.log("Ref exists, scrolling", artistProfileToolsRef.current);
            setTimeout(() => {
                artistProfileToolsRef.current!.scrollIntoView({ 
                    behavior: 'smooth', 
                    block: 'start' 
                });
            }, 100); // Delay to ensure DOM is fully rendered
        } else {
            console.log("Ref does not exist");
        }
    };

    return (
        <div className="flex flex-col min-h-screen mx-auto overflow-hidden">
            {/* Hero Section with Background Image */}
            <section className="relative w-full flex items-center justify-center min-h-[90vh] overflow-hidden">
                <div 
                    className={`absolute inset-0 bg-cover bg-top transition-opacity duration-2000 ease-in-out ${activeImage === 1 ? 'opacity-100' : 'opacity-0'}`}
                    style={{ backgroundImage: `url(${heroBackgroundImageGuitar})` }}
                />
                <div 
                    className={`absolute inset-0 bg-cover bg-top transition-opacity duration-2000 ease-in-out ${activeImage === 0 ? 'opacity-100' : 'opacity-0'}`}
                    style={{ backgroundImage: `url(${heroBackgroundImageHipHop})` }}
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#0B0D0D] opacity-90" />
                <div className="absolute inset-0 bg-black bg-opacity-30" />
                <div className="relative w-full max-w-screen-lg mx-auto text-center px-8 py-12 md:py-16 flex flex-col items-center justify-center min-h-[90vh]">
                    <h1 className="font-syne text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-medium mb-4 sm:mb-7 leading-tight">
                        Make music. Not content.
                    </h1>
                    <p className="font-syne font-semibold text-xl sm:text-2xl md:text-3xl text-gray-200 mb-8 sm:mb-12">
                        We&apos;re democratising patronage to harness the same energy that drives merch and Bandcamp sales into a reliable income for you.
                    </p>
                    <div className="flex justify-center">
                        <CoralButton onClick={scrollToArtistProfileTools}>GENERATE YOUR ARTIST PROFILE</CoralButton>
                    </div>
                </div>
            </section>

            {/* Key Advantages Section */}
            <section className="bg-gradient-to-b from-[#080909] to-coral-deep-blue sm:pt-24 pb-8 text-white">
                <div className="max-w-screen-xl mx-auto px-8 lg:px-16">
                    <h2 className="text-xl md:text-2xl font-extrabold mb-8">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                            KEY ADVANTAGES
                        </span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div>
                            <h3 className="font-syne text-2xl mb-4">Beyond Simple</h3>
                            <p>No need to develop a content strategy or configure tiers. Your community can contribute before you even sign up. We&apos;ll hold funds for 9 months, giving you time to join and claim your support.</p>
                        </div>
                        <div>
                            <h3 className="font-syne text-2xl mb-4">Focus on Music Creation</h3>
                            <p>Unlike other platforms, there&apos;s no transactional exchange and no expectation to create &#34;content&#34;. Your community already enjoys your art on streaming platforms, leaving you free to focus on making more.</p>
                        </div>
                        <div>
                            <h3 className="font-syne text-2xl mb-4">Sustainable Support</h3>
                            <p>Receive a regular income to comfortably pursue your craft. Enjoy higher percentages compared to platforms like Bandcamp, Patreon, and merchandise sales.</p>
                        </div>
                    </div>
                </div>
            </section>            

            {/* Our Strategy Section */}
            <section className="bg-coral-deep-blue py-8 text-white">
                <div className="max-w-screen-lg mx-auto sm:text-center px-8 lg:px-16">
                <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                        OUR STRATEGY
                    </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-3xl">
                    More money, distributed equitably.
                </h3>
                </div>
            </section>
            <section 
                className="relative bg-coral-deep-blue bg-no-repeat bg-contain bg-bottom 3xl:bg-cover pb-6 sm:pb-16 md:pb-24 text-white"
                style={{ 
                    backgroundImage: `url(${mainBackgroundImage})`,
                }}
            >
                <div className="max-w-screen-lg mx-auto sm:text-center px-6 lg:px-8 text-lg md:text-xl">                    
                    <p className="mb-8">
                        Your fans want to support you, but existing channels fall short. Gigs and merch are great, but dependent on time and place. Vinyl caters to a niche audience, and platforms like Bandcamp fragment listeners&apos; music libraries. Patreon demands singular support, and that you create content beyond your music.
                    </p>

                    <p className="mb-12">
                        wearecoral offers a frictionless way for fans to contribute directly, without the noise. This distilled support, combines quickly into meaningful income.
                    </p>
                    
                    <h3 className="text-xl md:text-2xl mb-4">
                        <span className="bg-clip-text font-extrabold text-transparent bg-gradient-to-r from-coral-pink to-coral-grad-blue-light">
                            HERE&apos;S HOW
                        </span>
                    </h3>
                    
                    <p className="font-syne text-xl md:text-2xl max-w-3xl mx-auto mb-12">
                        Collective Micro-Patronage: Members create their own &ldquo;coral&ldquo;, choosing one or more artists to support. They fund their coral monthly and the money is distributed amongst the artists in their coral.
                    </p>
                    
                    <div className="mb-12">
                        <RecentCoralVisualisation />
                    </div>

                    <h3 className="text-xl md:text-2xl mb-4">
                        <span className="bg-clip-text font-extrabold text-transparent bg-gradient-to-r from-coral-pink to-coral-grad-blue-light">
                            HERE&apos;S WHY
                        </span>
                    </h3>
                    
                    <p className="font-syne text-xl md:text-2xl max-w-3xl mx-auto mb-4">
                    Patronage Works: It&apos;s a sustainable approach that emphasises trust over transactions, freeing artists to create music that truly inspires them.
                    </p>

                    <p className="font-syne text-xl md:text-2xl max-w-3xl mx-auto mb-12">
                    While patronage has historically catalysed groundbreaking art, it hasn&apos;t been democratised for music in a transparent, accessible way—until now.
                    </p>                    

                    <div className="flex justify-center mb-64">
                        <CoralButton onClick={scrollToArtistProfileTools}>GENERATE YOUR ARTIST PROFILE</CoralButton>
                    </div>
                    <div className="py-0 sm:py-8 xl:py-24 3xl:py-80">
                    </div>
                </div>
            </section>

            {/* Ecosystem Approach Section */}
            <section className="bg-[#0C142D] text-white">
                <div className="py-8 md:py-16 mx-auto px-8 lg:px-16">
                    <div className="max-w-screen-xl mx-auto">
                        <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
                            <div className="lg:w-1/2 mb-8 lg:mb-0">
                                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                        ECOSYSTEM APPROACH
                                    </span>
                                </h2>
                                <h3 className="font-syne text-2xl sm:text-3xl lg:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                                    Our approach encourages collaboration among artists and scenes. What benefits each, benefits all.
                                </h3>
                            </div>
                            <div className="lg:w-1/2 mb-6 sm:mb-14">
                                <CoralEcosystem />
                            </div>
                        </div>
                    </div>
                </div>
                <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
            </section>

            {/* Coordinated Scale Section */}
            <section className="bg-coral-deep-blue text-white">
                <div className="max-w-screen-xl mx-auto px-8 lg:px-16">
                    <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                            COORDINATED SCALE
                        </span>
                    </h2>
                    <p className="text-xl mb-8">
                        Our platform enables communities to cooperate at scale. The math is compelling, explore the dynamics of scale below.
                    </p>
                    <CollectiveImpactTable />
                    <div className="flex justify-center mt-16">
                        <CoralButton onClick={scrollToArtistProfileTools}>GENERATE YOUR ARTIST PROFILE</CoralButton>
                    </div>
                </div>
                <div className="relative mt-16">
                    <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full" />
                    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
                </div>
            </section>



            {/* Next Steps Section */}
            <section className="bg-coral-dark-blue text-white pb-16"  ref={artistProfileToolsRef}>
                <div className="max-w-screen-xl mx-auto px-8 pb-8">
                    <h2 className="text-xl md:text-2xl font-extrabold mb-8">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                            WHAT&apos;S NEXT?
                        </span>
                    </h2>
                    <ol className="space-y-4 relative mt-4 text-sm sm:text-base md:text-lg">
                        <div className="absolute left-2 top-1 bottom-0 w-1 bg-gradient-to-b from-coral-pink to-transparent"></div>
                        {[
                            "Use the tool below to generate your artist profile and assets",
                            "Share them with your community via Linktree, socials, and at gigs",
                            "When you receive your first contribution, we'll contact you via your official channels (Instagram, website, or manager) with a unique code",
                            "Visit the payment collection link, provide your email, unique code, and PayPal username to sign up and start receiving monthly payments"
                        ].map((step, index) => (
                            <li key={index} className="flex items-start">
                                <div className="bg-coral-pink rounded-full w-5 h-5 mr-4 flex justify-center items-center flex-shrink-0">
                                    <div className="rounded-full w-3 h-3"></div>
                                </div>
                                <span>{step}</span>
                            </li>
                        ))}
                    </ol>
                    <p className="text-sm sm:text-base md:text-lg mt-8">
                        Read our <a href="/artist-terms" rel="noopener noreferrer" className="text-coral-pink">Artist Terms and Conditions</a> for more details on how we can cooperate.
                    </p>
                </div>
                <div className="max-w-screen-xl mx-auto px-8 lg:px-16">
                    <div className="sm:pb-8">
                        <ArtistProfileTools />
                    </div>
                    <div className="sm:mb-12">
                            <ArtistPayoutChecker />
                    </div>                
                </div>
            </section>

            {/* FAQ Section */}
            <section className="bg-coral-dark-blue text-white p-8 md:p-16 px-8 lg:px-16">
                <div className="max-w-screen-xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
                        <div>
                            <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                    SUPPORT
                                </span>
                            </h2>
                            <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">
                                FAQs
                            </h3>
                            <p className="text-xl mb-8">
                                Everything you need to know about wearecoral and how it operates.
                            </p>
                        </div>
                        <div className="space-y-4">
                            {faqData.map((faq, index) => (
                                <FAQItem key={index} question={faq.question} answer={faq.answer} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Final CTA Section */}
            <section className="bg-coral-dark-blue text-white p-16 px-8 lg:px-16">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                <h2 className="font-syne text-3xl md:text-3xl pb-6 text-center">
                        A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                    </h2>
                    <div className="flex justify-center mt-8">
                        <JoinCoralButton>JOIN WEARECORAL</JoinCoralButton>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForArtists;