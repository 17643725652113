import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Sidebar from './components/Sidebar/Sidebar';
import AuthModal from './components/AuthModal/AuthModal';
import Spinner from './components/Spinner/Spinner';
import { AuthContext } from './components/AuthContext/AuthContext';
import './styles/tailwind.css';
import '@aws-amplify/ui-react/styles.css';
import './styles/authenticatorStyles.css';
import { initializeAmplitude } from 'shared/lib/eventTracking';

initializeAmplitude(process.env.REACT_APP_AMPLITUDE_API_KEY!);

interface AppProps {
  children: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  const { showAuth, setShowAuth, currentUser, loading, fetchUserData } = React.useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  const handleAuthSuccess = async () => {
    await fetchUserData();
    setShowAuth({ show: false, signIn: false });
    history.push('/dashboard')
  };

  const isPublicPage = isPublicRoute(location.pathname);
  const shouldRenderSidebar = currentUser !== null && isSidebarPage(location.pathname);

  if (loading) {
      return (
          <div className="flex justify-center items-center h-screen">
              <Spinner />
          </div>
      );
  }

  return (
    <div className={`min-h-screen flex flex-col ${handleColorScheme(location.pathname)}`} style={{ minHeight: '-webkit-fill-available', paddingTop: 'env(safe-area-inset-top)', paddingBottom: 'env(safe-area-inset-bottom)' }}>
      {showAuth.show && !currentUser && (
        <AuthModal onAuthSuccess={handleAuthSuccess} setShowAuth={setShowAuth} signIn={showAuth.signIn} />
      )}
      <div className={`flex flex-col ${isPublicPage ? '' : 'h-safe'}`}>
        {isPublicPage && location.pathname !== '/welcome' && (
          <Header className={handleHeaderColor(location.pathname)} navigationTextHandler={handleNavigationTextColor(location.pathname)} />
        )}
        <div className="flex-grow flex flex-col sm:flex-row overflow-hidden">
          {shouldRenderSidebar && <Sidebar className="w-full sm:w-20 p-4" />}
          <div className="flex-grow overflow-auto">
            {children}
          </div>
        </div>
        {isPublicPage && location.pathname !== '/welcome' && <Footer />}
      </div>
    </div>
  );
};

function isPublicRoute(route: string): boolean {
  const exactPublicRoutes = ['/', '/about', '/contact-us', '/privacy', '/terms', '/artist-terms', '/for-artists', '/welcome', '/sign-out', '/trust', '/thankyou','/principles'];
  if (exactPublicRoutes.includes(route)) {
    return true;
  }
  if (route.startsWith('/c/') || route.startsWith('/a/')) {
      return true;
  }
  return false;
}

function isSidebarPage(page: string): boolean {
  const pagesWithSidebar = ['/dashboard', '/coral', '/settings', '/impact'];
  return pagesWithSidebar.some(p => page.startsWith(p));
}

function handleColorScheme(pathname: string): string {
  if (pathname === '/dashboard') return 'bg-coral-blue';
  if (pathname.startsWith('/c/')) {
    return 'bg-gradient-to-b from-primary-400 to-primary-800 text-white';
  }
  return '';
}

function handleHeaderColor(pathname: string): string {
  if (pathname.startsWith('/c/')) return 'bg-transparent text-white';
  return 'bg-white';
}

function handleNavigationTextColor(pathname: string): string {
  if (pathname.startsWith('/c/')) return 'text-gray-300 hover:text-white transition duration-200 text-xs md:text-sm lg:text-base';
  return 'text-gray-600 hover:text-gray-900 transition duration-200 text-sm md:text-base lg:text-lg';
}

export default App;
