import React, { useState, useEffect } from 'react';
import { ArtistApplication } from 'shared/types/platformTypes';
import { getArtistApplications, processArtistApplication } from '../../../../api/adminApi';

const ArtistApplicationAdmin: React.FC = () => {
  const [applications, setApplications] = useState<ArtistApplication[]>([]);
  const [expandedApplicationId, setExpandedApplicationId] = useState<string | null>(null);
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [editingGuid, setEditingGuid] = useState<string | null>(null);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const fetchedApplications = await getArtistApplications();
        setApplications(fetchedApplications);
      } catch (error) {
        console.error('Failed to fetch applications', error);
        setNotification({ type: 'error', message: 'Failed to fetch applications. Please try again.' });
      }
    };

    fetchApplications();
  }, []);

  const handleProcess = async (applicationId: string, status: 'APPROVED' | 'REJECTED', artistGuid: string) => {
    try {
      await processArtistApplication(applicationId, status, artistGuid);
      setApplications(applications.filter(a => a.applicationId !== applicationId));
      setNotification({ type: 'success', message: `Application ${status.toLowerCase()} successfully.` });
    } catch (error) {
      console.error('Failed to process application', error);
      setNotification({ type: 'error', message: 'Failed to process application. Please try again.' });
    }
  };

  const toggleExpand = (applicationId: string) => {
    setExpandedApplicationId(expandedApplicationId === applicationId ? null : applicationId);
  };

  const toggleEditGuid = (applicationId: string) => {
    setEditingGuid(editingGuid === applicationId ? null : applicationId);
  };

  const updateArtistGuid = (applicationId: string, newGuid: string) => {
    setApplications(applications.map(app => 
      app.applicationId === applicationId ? { ...app, artistGuid: newGuid } : app
    ));
    setEditingGuid(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <a 
          href="/admin/home" 
          className="text-indigo-600 hover:text-indigo-800 font-medium"
        >
          ← Back to Admin Home
        </a>
      </div>
      <h1 className="text-3xl font-bold mb-6">Artist Application Admin</h1>
      {notification && (
        <div className={`mb-4 p-4 rounded ${notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
          <p className="font-bold">{notification.type === 'success' ? 'Success' : 'Error'}</p>
          <p>{notification.message}</p>
        </div>
      )}
      <div className="space-y-4">
        {applications.map((application) => (
          <div key={application.applicationId} className="bg-white shadow rounded-lg overflow-hidden">
            <div 
              className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-50" 
              onClick={() => toggleExpand(application.applicationId!)}
            >
              <div>
                <h2 className="text-xl font-semibold">{application.artistName}</h2>
                <p className="text-sm text-gray-500">{application.legalEntityName}</p>
              </div>
              <div className="flex items-center space-x-4">
                <span className={`px-2 py-1 rounded text-sm ${
                  application.applicationStatus === 'PENDING' ? 'bg-yellow-200 text-yellow-800' :
                  application.applicationStatus === 'APPROVED' ? 'bg-green-200 text-green-800' :
                  'bg-red-200 text-red-800'
                }`}>
                  {application.applicationStatus}
                </span>
                <svg 
                  className={`w-5 h-5 transition-transform ${expandedApplicationId === application.applicationId ? 'transform rotate-180' : ''}`} 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
            {expandedApplicationId === application.applicationId && (
              <div className="p-4 border-t">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h3 className="font-semibold">Artist GUID</h3>
                    <div className="flex items-center">
                      {editingGuid === application.applicationId ? (
                        <input 
                          type="text" 
                          value={application.artistGuid || ''} 
                          onChange={(e) => updateArtistGuid(application.applicationId!, e.target.value)}
                          className="border rounded px-2 py-1 mr-2"
                        />
                      ) : (
                        <p>{application.artistGuid || 'N/A'}</p>
                      )}
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleEditGuid(application.applicationId!);
                        }}
                        className="ml-2 text-blue-500 hover:text-blue-700"
                      >
                        {editingGuid === application.applicationId ? 'Save' : 'Edit'}
                      </button>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold">Legal Structure</h3>
                    <p>{application.legalStructure}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Company Number</h3>
                    <p>{application.companyNumber || 'N/A'}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Company Number Type</h3>
                    <p>{application.companyNumberType || 'N/A'}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Registered Country</h3>
                    <p>{application.registeredCountry}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Phone Number</h3>
                    <p>{application.phoneNumber}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Streaming Purchase Link</h3>
                    <a href={application.streamingPurchaseLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                      {application.streamingPurchaseLink}
                    </a>
                  </div>
                  <div>
                    <h3 className="font-semibold">Payment Method</h3>
                    <p>{application.paymentPreferences.paymentMethod}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Payment Account</h3>
                    <p>{application.paymentPreferences.paymentDetails.paymentAccount}</p>
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="font-semibold">Public Profiles</h3>
                  <ul className="list-disc list-inside">
                    {application.publicProfiles?.map((profile, index) => (
                      <li key={index}>
                        {profile.type}: <a href={profile.url.startsWith('http') ? profile.url : `https://${profile.type}.com/${profile.url}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{profile.url}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    onClick={() => handleProcess(application.applicationId!, 'APPROVED', application.artistGuid || '')}
                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Approve
                  </button>
                  <button
                    onClick={() => handleProcess(application.applicationId!, 'REJECTED', application.artistGuid || '')}
                    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded flex items-center"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Reject
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArtistApplicationAdmin;