import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../components/AuthContext/AuthContext';

const SignOut: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    const signOut = async () => {
      await logout();
      history.push('/');
    };
    signOut();
  }, [logout, history]);

  return <div>Signing out...</div>;
};

export default SignOut;