import React, { useState, useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import mainBackgroundImage from '../../assets/images/coral-reef-black.svg';
import blueWaveImage from '../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../assets/images/dark-blue-wave.png';
import FAQItem from '../../components/FAQItem/faqItem';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { faqData } from './faqData';
import CollectiveImpactCalculator from '../../components/CollectiveImpactTable/CollectiveImpactCalculator';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import RecentCoralVisualisation from '../../components/RecentCoralVisualisation/RecentCoralVisualisation';
import CoralEcosystem from '../../components/CoralEcosystem/CoralEcosystem';
import { Tooltip } from 'react-tooltip';
import heroBackgroundImageGuitar from '../../assets/images/hero-image-man-guitar.jpg';
import heroBackgroundImageElectronic from '../../assets/images/hero-image-producer.jpg';
import heroBackgroundImageMc from '../../assets/images/hero-image-mc.jpg';

const Home: React.FC = () => {
    React.useEffect(() => {
        trackEvent(EventName.HOME_PAGE_VIEW, {});
    }, []);

    const [activeImage, setActiveImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveImage((prev) => (prev === 2 ? 0 : prev + 1));
        }, 10000); // Change image every 10 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex flex-col min-h-screen mx-auto overflow-hidden">
            {/* Hero Section with Background Image */}
            <section className="relative w-full flex items-center justify-center min-h-[90vh] overflow-hidden">
                <div 
                    className={`absolute inset-0 bg-cover bg-top transition-opacity duration-2000 ease-in-out ${activeImage === 0 ? 'opacity-100' : 'opacity-0'}`}
                    style={{ backgroundImage: `url(${heroBackgroundImageGuitar})` }}
                />
                <div 
                    className={`absolute inset-0 bg-cover bg-top transition-opacity duration-2000 ease-in-out ${activeImage === 1 ? 'opacity-100' : 'opacity-0'}`}
                    style={{ backgroundImage: `url(${heroBackgroundImageElectronic})` }}
                />
                <div 
                    className={`absolute inset-0 bg-cover bg-top transition-opacity duration-2000 ease-in-out ${activeImage === 2 ? 'opacity-100' : 'opacity-0'}`}
                    style={{ backgroundImage: `url(${heroBackgroundImageMc})` }}
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#0B0D0D] opacity-90" />
                <div className="absolute inset-0 bg-black bg-opacity-30" />
                <div 
                    className="absolute inset-0 opacity-[0.03] mix-blend-overlay"
                    style={{
                        backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
                        backgroundSize: '200px 200px'
                    }}
                />
                <div className="relative w-full max-w-screen-lg mx-auto text-center px-8 py-12 md:py-16 flex flex-col items-center justify-center min-h-[90vh]">
                    <h1 className="font-syne text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-medium mb-4 sm:mb-7 leading-tight mt-24">
                        The simplest way to support the musicians you love.
                    </h1>
                    <p className="font-syne font-semibold text-xl sm:text-2xl md:text-3xl text-gray-200 mb-8 sm:mb-12">
                        Patronage that&apos;s transparent, collective, and easy.
                    </p>
                    <div className="flex justify-center">
                        <JoinCoralButton>CREATE YOUR CORAL</JoinCoralButton>
                    </div>
                </div>
            </section>

            {/* Coral Details and Visualization Section */}
            <section className="bg-gradient-to-b from-[#080909] to-coral-deep-blue py-16 text-white">
                <div className="max-w-screen-lg mx-auto text-center px-3 lg:px-8">
                    <h2 className="font-syne text-3xl sm:text-4xl md:text-5xl pl-4 pr-4 mb-12">
                        The music you love struggles in a system that transacts by the stream.
                    </h2>
                    <h2 className="font-syne text-3xl sm:text-4xl md:text-5xl pl-4 pr-4">
                        The economics of music don&apos;t add up, so we&apos;re changing the tune.
                    </h2>
                </div>
            </section>
            <section 
                className="relative bg-coral-deep-blue bg-no-repeat bg-contain bg-bottom 3xl:bg-cover text-white"
                style={{ 
                    backgroundImage: `url(${mainBackgroundImage})`,
                }}
            >
                <div className="max-w-screen-lg mx-auto text-center px-3 lg:px-8">                   
                    <h3 className="text-xl md:text-2xl mb-4">
                        <span className="bg-clip-text font-extrabold text-transparent bg-gradient-to-r from-coral-pink to-coral-grad-blue-light">
                            HERE&apos;S HOW
                        </span>
                    </h3>
                    
                    <h2 className="font-syne text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-6">
                        Create a Coral: add <span className="text-coral-pink" data-tooltip-id='any-artists' data-tooltip-html="Nominate artists, even if they're not on our platform.<br />We'll notify them of waiting funds.<br /><br />This community-driven approach reflects recognition of <br />the value we've already received from their music, and<br />is how we can step forward to value them.<br /><br />Artists have 9 months from the first notification to<br />sign up before funds are redistributed to other<br />artists you nominate, or refunded.">any artists</span> you want and set any monthly amount.
                    </h2>
                    
                    <p className="font-syne text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-6">
                        We pool contributions, then distribute funds monthly to supported artists.
                    </p>

                    <p className="font-syne text-transparent bg-clip-text bg-gradient-to-r from-coral-pink to-coral-orange text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-12">
                        Enjoy vibrant, diverse music scenes you help sustain.
                    </p>
                    
                    <Tooltip id="any-artists" className="z-50" />
                    
                    <div className="mb-12">
                        <RecentCoralVisualisation />
                    </div>

                    <div className="flex justify-center mb-16">
                        <JoinCoralButton>CREATE YOUR CORAL</JoinCoralButton>
                    </div>
                </div>
            </section>

            {/* Mission Section */}
            <section className="bg-[#0C142D] text-white">
                <div className="p-8 md:p-16">
                    <div className="max-w-screen-xl mx-auto">
                        <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
                            <div className="lg:w-1/2 mb-8 lg:mb-0">
                                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                        OUR VISION
                                    </span>
                                </h2>
                                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                                    A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                                </h3>
                            </div>
                            <div className="lg:w-1/2 mb-6 sm:mb-14">
                                <CoralEcosystem />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <JoinCoralButton>Add Your Coral to the Ecosystem</JoinCoralButton>
                        </div>
                    </div>
                </div>
                <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
            </section>

            {/* How It Works Section */}
            <section className="bg-coral-deep-blue text-white">
                <div className="max-w-screen-xl mx-auto px-8 sm:px-6 lg:px-8">
                    <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                            CORAL DYNAMICS
                        </span>
                    </h2>
                    <CollectiveImpactCalculator />
                    <div className="flex justify-center mt-16">
                        <JoinCoralButton>GO TO DASHBOARD</JoinCoralButton>
                    </div>
                </div>
                <div className="relative mt-16">
                    <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full" />
                    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="bg-coral-dark-blue text-white p-8 md:p-16">
                <div className="max-w-screen-xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
                        <div>
                            <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                    SUPPORT
                                </span>
                            </h2>
                            <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">
                                FAQs
                            </h3>
                            <p className="text-xl mb-8">
                                Everything you need to know about wearecoral and how it operates.
                            </p>
                        </div>
                        <div className="space-y-4">
                            {faqData.map((faq, index) => (
                                <FAQItem key={index} question={faq.question} answer={faq.answer} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;