import React, { useState, useEffect, useCallback } from 'react';
import { ToggleLeft, ToggleRight } from 'lucide-react';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import debounce from 'lodash/debounce';

const CollectiveImpactTable = () => {
  const [monthlyListeners, setMonthlyListeners] = useState('');
  const [contributionAmount, setContributionAmount] = useState(0.7);
  const [simulationResults, setSimulationResults] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<'table' | 'list'>('table');

  const listenerCounts = [5000, 20000, 100000, 500000];
  const percentages = [0.01, 0.02, 0.05, 0.1];

  const calculateImpact = (listeners: number, percentage: number) => {
    return (listeners * percentage * contributionAmount).toFixed(2);
  };

  const debouncedTrackEvent = useCallback(
    debounce((listeners: string, contribution: number) => {
      if (parseInt(listeners) > 0) {
        trackEvent(EventName.COLLECTIVE_TABLE_INTERACTION, {
          monthlyListeners: parseInt(listeners) || 0,
          contributionAmount: contribution
        });
      }
    }, 1250),
    []
  );

  useEffect(() => {
    if (monthlyListeners) {
      const listeners = parseInt(monthlyListeners);
      setSimulationResults(percentages.map(p => parseFloat(calculateImpact(listeners, p))));
    } else {
      setSimulationResults([]);
    }

    debouncedTrackEvent(monthlyListeners, contributionAmount);
  }, [monthlyListeners, contributionAmount, debouncedTrackEvent]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setViewMode('list');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMonthlyListenersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonthlyListeners(e.target.value);
  };

  const handleContributionAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContributionAmount(parseFloat(e.target.value));
  };

  const TableView = () => (
    <div className="overflow-x-auto py-4 text-gray-500">
      <table className="w-full text-xs">
        <thead className="bg-gray-50 text-gray-500">
          <tr>
            <th scope="col" className="px-1 py-2 text-left">Listeners</th>
            {percentages.map((percentage) => (
              <th key={percentage} scope="col" className="px-1 py-2 text-right">
                {(percentage * 100)}%
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {listenerCounts.map((listeners) => (
            <tr key={listeners} className="border-b">
              <td className="px-1 py-2 whitespace-nowrap font-semibold">{listeners.toLocaleString()}</td>
              {percentages.map((percentage) => (
                <td key={percentage} className="px-1 py-2 whitespace-nowrap text-right">
                  ${calculateImpact(listeners, percentage)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const ListView = () => (
    <div className="space-y-4">
      {listenerCounts.map((listeners) => (
        <div key={listeners} className="bg-white p-3 rounded-lg shadow text-sm">
          <h4 className="font-semibold mb-2">{listeners.toLocaleString()} Listeners</h4>
          <ul className="space-y-1">
            {percentages.map((percentage) => (
              <li key={percentage} className="flex justify-between">
                <span>{(percentage * 100)}%:</span>
                <span className="font-semibold">${calculateImpact(listeners, percentage)}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-full mx-auto p-3 sm:p-4 bg-white rounded-lg shadow-md text-gray-600">
      <h2 className="font-nunito text-lg sm:text-xl font-bold text-coral-black mb-2 sm:mb-3">Explore the Dynamics of Scale</h2>
      <p className="text-xs sm:text-sm text-gray-600 mb-3">
        This calculator shows monthly earnings based on a ${contributionAmount.toFixed(2)} contribution per listener.
      </p>
      
      <div className="mb-3 flex items-center space-x-2">
        <button 
          onClick={() => setViewMode(viewMode === 'table' ? 'list' : 'table')}
          className="text-coral-pink hover:text-coral-orange transition-colors"
        >
          {viewMode === 'table' ? <ToggleLeft size={20} /> : <ToggleRight size={20} />}
        </button>
        <span className="text-xs font-medium">{viewMode === 'table' ? 'Table' : 'List'} View</span>
      </div>

      {viewMode === 'table' ? <TableView /> : <ListView />}

      <div className="mt-4 bg-gray-50 p-3 rounded-lg">
        <h3 className="font-nunito text-base sm:text-lg font-bold text-coral-black mb-2">Estimate Your Support</h3>
        <div className="grid grid-cols-2 gap-2 mb-3">
          <div className="flex flex-col justify-between h-full">
            <label className="block text-xs font-medium text-gray-700" htmlFor="monthly-listeners">
              Monthly Listeners
            </label>
            <input
              id="monthly-listeners"
              type="number"
              value={monthlyListeners}
              onChange={handleMonthlyListenersChange}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-coral-pink mt-auto"
              placeholder="Listeners"
            />
          </div>
          <div className="flex flex-col justify-between h-full">
            <label className="block text-xs font-medium text-gray-700" htmlFor="contribution-amount">
              Contribution ($)
            </label>
            <input
              id="contribution-amount"
              type="number"
              value={contributionAmount}
              onChange={handleContributionAmountChange}
              step="0.1"
              min="0"
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-coral-pink mt-auto"
              placeholder="Amount"
            />
          </div>
        </div>
        {simulationResults.length > 0 && (
          <div className="overflow-x-auto">
            <table className="w-full text-xs">
              <thead className="bg-gradient-to-r from-coral-pink to-coral-orange text-white">
                <tr>
                  <th scope="col" className="px-1 py-2 text-left">Listeners %</th>
                  {percentages.map((percentage) => (
                    <th key={percentage} scope="col" className="px-1 py-2 text-right">
                      {(percentage * 100)}%
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr className="border-b">
                  <td className="px-1 py-2 whitespace-nowrap font-semibold">{parseInt(monthlyListeners).toLocaleString()}</td>
                  {simulationResults.map((result, index) => (
                    <td key={index} className="px-1 py-2 whitespace-nowrap text-right font-semibold text-coral-pink">
                      ${result}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectiveImpactTable;