import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ArtistSearchResult, ClientArtistListItem, Source } from 'shared/types/platformTypes';
import useCoralEditor from '../../hooks/useCoralEditor';
import { useAuthContext } from '../AuthContext/AuthContext';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import ArtistSearch from '../../components/ArtistSearch/ArtistSearch';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import { Tooltip } from 'react-tooltip';
import { ChevronDown, ChevronUp, Edit2 } from 'lucide-react';
import selectedStar from '../../assets/images/selected-star.png';
import unSelectedStar from '../../assets/images/unselected-star.png';
import disabledIcon from '../../assets/images/disabled-icon.png';
import plusIcon from '../../assets/images/create-coral/add-artist.png';
import addedIcon from '../../assets/images/create-coral/added-artist.png';
import CoralVisualisation from '../../components/CoralVisualisation/CoralVisualisation';
import { useHistory } from 'react-router-dom';

interface AllInOneCoralCreatorProps {
  artistId?: string;
}

const AllInOneCoralCreator: React.FC<AllInOneCoralCreatorProps> = ({ artistId }) => {
  const { userProfile } = useAuthContext();
  const {
    coralData,
    addArtistDirectly,
    removeArtist,
    updatePledgeAmount,
    saveCoral,
    simulateCoral,
    simulationResults,
    addPinnedArtist,
    removePinnedArtist,
  } = useCoralEditor('', false, artistId);

  const [pledgeValue, setPledgeValue] = useState('');
  const [isEditingPledge, setIsEditingPledge] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isAddingArtist, setIsAddingArtist] = useState(false);
  const [isFeesExpanded, setIsFeesExpanded] = useState(false);

  const history = useHistory();

  const toggleFees = () => {
    setIsFeesExpanded(!isFeesExpanded);
  };

  const totalFees = useMemo(() => {
    return simulationResults.platformFee + simulationResults.transactionFee;
  }, [simulationResults.platformFee, simulationResults.transactionFee]);

  const selectedArtists = coralData?.artistPool?.artistList.filter(artist =>
    artist.selected || artist.source === Source.USER
  ) || [];

  useEffect(() => {
    if (coralData) {
      setPledgeValue(coralData.pledgeAmount.toString());
    }
  }, [coralData]);

  useEffect(() => {
    if (coralData && coralData.pledgeAmount > 0) {
      simulateCoral();
    }
  }, [coralData?.pledgeAmount, simulateCoral]);

  const handleUpdatePledgeAmount = useCallback(async () => {
    const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;

    if (decimalRegex.test(pledgeValue)) {
      const amount = Number(pledgeValue);
      if (amount < 1 || amount > 70) {
        setValidationMessage('Amount must be between $1 and $70');
      } else {
        setValidationMessage('');
        setIsEditingPledge(false);

        await updatePledgeAmount(amount);

        if (userProfile) {
          trackEvent(EventName.SIMULATE_CORAL, {
            userId: userProfile!.userId,
            amount: amount,
            artistCount: coralData!.artistPool.artistList.length,
          });
        }
      }
    } else {
      setValidationMessage('Invalid pledge amount');
    }
  }, [pledgeValue, updatePledgeAmount, userProfile, coralData]);

  const [isResponsiveLayout, setIsResponsiveLayout] = useState(false);

  const shouldUseResponsiveLayout = useCallback(() => {
    if (selectedArtists.length >= 3) return true;
    return selectedArtists.some(artist => artist.artistName && artist.artistName.length > 11);
  }, [selectedArtists]);

  useEffect(() => {
    setIsResponsiveLayout(shouldUseResponsiveLayout());
  }, [shouldUseResponsiveLayout]);

  const handleAddArtist = useCallback(async (artistName: string, artistGuid?: string) => {
    if (artistName.trim()) {
      try {
        await addArtistDirectly(artistName.trim(), artistGuid);
        setIsAddingArtist(false);
      } catch (error) {
        console.error('Error adding artist:', error);
      }
    }
  }, [addArtistDirectly]);

  const handleSelectArtist = useCallback((artist: ArtistSearchResult) => {
    handleAddArtist(artist.name, artist.guid);
  }, [handleAddArtist]);

  const handleAddToEcosystem = useCallback(async () => {
    try {
      const savedCoralGuid = await saveCoral();
      if (userProfile) {
        trackEvent(EventName.CREATE_CORAL_GOTO_PAYMENT, {
          userId: userProfile!.userId,
          coralId: savedCoralGuid!,
          amount: coralData!.pledgeAmount
        });
      }
      history.push(`/payment/${savedCoralGuid}?amt=${coralData?.pledgeAmount}`);
      // Navigate to payment page or show success message
    } catch (error) {
      console.error('Error saving coral:', error);
      // Show error message to user
    }
  }, [saveCoral, userProfile, coralData]);

  const renderArtistStatus = useCallback((artist: ClientArtistListItem) => {
    if (artist.artistListItemStatus === 'pending') {
      return (
        <button data-tooltip-id="searchingTooltip" data-tooltip-content="We're searching for this artist in our database. Try a different spelling if nothing returns.">
          <Spinner className="w-5 h-5 animate-pulse" />
          <Tooltip id="searchingTooltip" />
        </button>
      );
    } else if (artist.artistListItemStatus === 'not found') {
      return <img src={plusIcon} alt="Add artist" className="w-5 h-5" />;
    } else if (artist.artistListItemActive === false) {
      return (
        <button data-tooltip-id="disabledIconTooltip" data-tooltip-content={
          artist.artistListItemStatus === 'top-artist'
            ? "This artist has reached high levels of commercial success and is not eligible for wearecoral."
            : artist.artistListItemStatus === "deceased"
              ? "wearecoral is for living artists as it's about supporting a thriving ecosystem."
              : "This artist is not eligible for wearecoral."
        }>
          <img src={disabledIcon} alt="Artist is disabled" className="w-4 h-4" />
          <Tooltip id="disabledIconTooltip" />
        </button>
      );
    } else {
      return <img src={addedIcon} alt="Artist added" className="w-5 h-5" />;
    }
  }, []);

  const handleRemoveArtist = useCallback((artistName: string) => {
    if (window.confirm("Are you sure you want to remove this artist?")) {
      removeArtist(artistName);
    }
  }, [removeArtist]);

  const handleTogglePinArtist = useCallback((artistId: string) => {
    if (coralData && coralData?.pinnedArtists.includes(artistId)) {
      removePinnedArtist(artistId);
    } else if (coralData && coralData.pinnedArtists && coralData?.pinnedArtists.length < 2) {
      addPinnedArtist(artistId);
    }
  }, [coralData, addPinnedArtist, removePinnedArtist]);

  // Prepare data for CoralVisualisation
  const coralVisualisationData = useMemo(() => {
    if (!coralData || !simulationResults || coralData.pledgeAmount === 0 || (coralData.artistPool.artistList ?? []).length === 0) return null;

    const genreData = coralData.artistPool?.artistList.reduce((acc, artist) => {
      const genre = artist.artistData?.primaryGenre; // Use primaryGenre instead
      if (genre) {
        const existingGenre = acc.find(g => g.name === genre);
        if (existingGenre) {
          existingGenre.amount += simulationResults.artistAllocations.find(a => a.artist_id === artist.artistId)?.allocated_amount || 0;
        } else {
          acc.push({
            name: genre,
            amount: simulationResults.artistAllocations.find(a => a.artist_id === artist.artistId)?.allocated_amount || 0
          });
        }
      }
      return acc;
    }, [] as { name: string, amount: number }[]);

    if (!genreData || genreData.length === 0) return null;

    return {
      transactionId: coralData.guid,
      totalAmount: coralData.pledgeAmount,
      genres: genreData || [],
      artists: simulationResults.artistAllocations
        .filter(a => a.allocated_amount > 0)
        .map(a => ({
          name: a.artist_name,
          amount: a.allocated_amount
        }))
    };
  }, [coralData, simulationResults]);

  return (
    <div className="max-w-2xl min-w-xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className={`flex flex-col ${!isResponsiveLayout && coralVisualisationData ? 'lg:flex-row' : ''}`}>
        {/* Left Section: Artists in Your Coral */}
        <div className={`w-full ${!isResponsiveLayout && coralVisualisationData ? 'lg:w-1/2 lg:pr-4' : ''} mb-8`}>
          <h3 className="font-nunito text-xl font-bold mb-4">Build Your Coral</h3>
          <h4 className="font-nunito text-sm font-bold text-gray-400 uppercase">Artists</h4>
          <div className="mb-4">
            {selectedArtists.map((artist, index) => (
              <div key={index} className="flex items-start justify-between py-2 border-b">
                <div className="flex items-start flex-grow mr-2 min-w-0">
                  <img
                    src={coralData?.pinnedArtists.includes(artist.artistId!) ? selectedStar : unSelectedStar}
                    alt="Pin artist"
                    className="w-6 h-6 cursor-pointer mr-4 flex-shrink-0"
                    onClick={() => handleTogglePinArtist(artist.artistId!)}
                  />
                  <span
                    className="font-medium text-gray-900 cursor-pointer break-words"
                    onClick={() => handleRemoveArtist(artist.artistName!)}
                  >
                    {artist.artistName}
                  </span>
                </div>
                <div className="flex items-center flex-shrink-0 w-24 justify-end">
                  <span className="mr-2 text-sm text-gray-600 whitespace-nowrap">
                    ${simulationResults.artistAllocations.find(a => a.artist_id === artist.artistId)?.allocated_amount.toFixed(2) || '0.00'}
                  </span>
                  <div className="w-5 h-5 flex-shrink-0">
                    {renderArtistStatus(artist)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isAddingArtist ? (
            <ArtistSearch
              onSelect={handleSelectArtist}
              onSubmit={handleAddArtist}
              placeholder="Enter artist name"
            />
          ) : (
            <button
              onClick={() => setIsAddingArtist(true)}
              className="text-coral-orange hover:text-coral-pink-dark transition-colors duration-300"
            >
              + Add more artists
            </button>
          )}
        </div>

        {/* Right Section: Visualize Your Coral */}
        {!isResponsiveLayout && coralVisualisationData && (
          <div className="w-full lg:w-1/2 lg:pl-4">
            <div className="flex justify-center items-center w-full pt-5">
              <CoralVisualisation coral={coralVisualisationData} chartOnly={true} size={180} />
            </div>
          </div>
        )}
      </div>

      {/* Visualization for responsive layout */}
      {isResponsiveLayout && coralVisualisationData && (
        <div className="w-full mb-8">
          <div className="flex justify-center items-center w-full">
            <CoralVisualisation coral={coralVisualisationData} chartOnly={true} size={180} />
          </div>
        </div>
      )}


            {/* Bottom Section: Fees & CTA */}
            <div className="pt-8 border-t border-gray-200">
        {/* Top Section: Monthly Contribution */}
        <div className="mb-1">
          <div className="flex items-center justify-between">
            <h2 className="font-nunito text-base font-bold text-gray-900">
              Monthly Contribution <span className="text-gray-600">(AUD)</span>
            </h2>
            <div className="flex items-center ml-4">
              <span className="mr-0 text-gray-600">$</span>
              {isEditingPledge ? (
                <input
                  type="text"
                  value={pledgeValue}
                  onChange={(e) => setPledgeValue(e.target.value)}
                  onBlur={handleUpdatePledgeAmount}
                  onKeyPress={(e) => e.key === 'Enter' && handleUpdatePledgeAmount()}
                  className="w-16 p-1 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  autoFocus
                  placeholder=""
                />
              ) : (
                <div
                  className={`flex items-center cursor-pointer rounded px-2 py-1 transition-colors duration-200 ${
                    coralData?.pledgeAmount === 0
                  }`}
                  onClick={() => {
                    if (coralData?.pledgeAmount === 0) {
                      setPledgeValue('');
                    } else {
                      setPledgeValue(coralData?.pledgeAmount.toFixed(2) || '');
                    }
                    setIsEditingPledge(true);
                  }}
                >
                  <span className="text-base mr-2">
                    {coralData?.pledgeAmount === 0 ? (
                      <i className="text-coral-pink text-sm hidden sm:block">Set amount</i>
                    ) : (
                      coralData?.pledgeAmount.toFixed(2)
                    )}
                  </span>
                  <Edit2
                    className={`${
                      coralData?.pledgeAmount === 0 ? 'text-coral-pink animate-pulse' : 'text-gray-600'
                    }`}
                  />
                </div>
              )}
            </div>
          </div>
          {coralData?.pledgeAmount === 0 && (
            <p className="text-gray-300 mt-2 text-center text-sm">We suggest contributing the price of a single drink at a local gig</p>
          )}
          {validationMessage && (
            <p className="text-red-500 mt-2 text-sm">{validationMessage}</p>
          )}
        </div>

        {/* Fees Section */}
        {coralData && coralData.pledgeAmount > 0 && (
          <div className="mb-6">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={toggleFees}
            >
              <h3 className="text-sm font-medium text-gray-400">Fees</h3>
              <div className="flex items-center">
                <span className="text-sm text-gray-600 mr-4">${totalFees.toFixed(2)}</span>
                {isFeesExpanded ? (
                  <ChevronUp size={20} className="text-gray-600" />
                ) : (
                  <ChevronDown size={20} className="text-gray-600" />
                )}
              </div>
            </div>
            {isFeesExpanded && (
              <div className="mt-2 space-y-2 text-gray-600">
                <div className="flex items-center justify-between text-sm">
                  <span>Platform fee (incl GST)</span>
                  <span>${simulationResults.platformFee.toFixed(2)}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span>Payment processing fee</span>
                  <span>${simulationResults.transactionFee.toFixed(2)}</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mt-4">
          <CoralButton
            onClick={handleAddToEcosystem}
            className={`w-full px-6 py-3 text-lg font-semibold ${coralData?.pledgeAmount === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={coralData?.pledgeAmount === 0}
          >
            Add Your Coral to the Ecosystem
          </CoralButton>
        </div>
      </div>
    </div>
  );
};

export default AllInOneCoralCreator;