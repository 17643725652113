import React, { useState, useCallback, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import ArtistSearch from '../ArtistSearch/ArtistSearch';
import { ArtistSearchResult, BlendedArtistListItem } from 'shared/types/platformTypes';
import { Copy, Check, AlertCircle, Download, Info } from 'lucide-react';
import useArtistFetchQueue from '../../hooks/useArtistFetch';
import Spinner from '../Spinner/Spinner';
import { Tooltip } from 'react-tooltip';
import ArtistBrandedImageCreator from '../ArtistBrandedImageCreator/ArtistBrandedImageCreator';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { getArtistDetailsPublic } from '../../api/api';

const ArtistProfileTools: React.FC = () => {
  const [selectedArtist, setSelectedArtist] = useState<BlendedArtistListItem | null>(null);
  const [email, setEmail] = useState('');
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [fetchedArtists, setFetchedArtists] = useState<BlendedArtistListItem[]>([]);
  const [lastSearchedArtist, setLastSearchedArtist] = useState<string | null>(null);

  const trackArtistProfileGeneration = useCallback((artistName: string, success: boolean) => {
    trackEvent(EventName.ARTIST_PROFILE_GENERATION, {
      artistName,
      email,
      success,
    });
    if (error && lastSearchedArtist && email)
      setError(null);
  }, [email]);

  const handleError = useCallback((errorMessage: string) => {
    setError(errorMessage);
    if (lastSearchedArtist) {
      trackArtistProfileGeneration(lastSearchedArtist, false);
    }
  }, [lastSearchedArtist, trackArtistProfileGeneration]);

  const onArtistsFetched = useCallback((fetchedArtists: BlendedArtistListItem[]) => {
    setIsSearching(false);
    if (fetchedArtists[0].artistListItemStatus === 'not found') {
      handleError("Artist not found. Please add your email so we can contact you when we add this artist. We're continually expanding our database.");
      setFetchedArtists([]);
    }
    else if (fetchedArtists.length === 1) {
      setSelectedArtist(fetchedArtists[0]);
      setError(null);
      trackArtistProfileGeneration(fetchedArtists[0].artistName!, true);
    } else if (fetchedArtists.length > 1) {
      setFetchedArtists(fetchedArtists);
      setError(null);
    } else {
      setFetchedArtists([]);
      handleError("Artist not found. Please add your email so we can contact you when we add this artist. We're continually expanding our database.");
    }
  }, [trackArtistProfileGeneration, handleError]);

  const { addArtistToQueue } = useArtistFetchQueue(onArtistsFetched);

  const handleArtistSelect = useCallback(async (artist: ArtistSearchResult) => {
    setIsSearching(true);
    setError(null);
    setCopied(false);
    setSelectedArtist(null);
    setFetchedArtists([]);
    setLastSearchedArtist(artist.name);

    if (artist.guid) {
      try {
        const artistDetails = await getArtistDetailsPublic(undefined, undefined, artist.guid);
        if (artistDetails.length > 0) {
          setSelectedArtist(artistDetails[0]);
          trackArtistProfileGeneration(artistDetails[0].artistName!, true);
        } else {
          handleError("Unable to fetch artist details. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching artist details:", error);
        handleError("An error occurred while fetching artist details. Please try again.");
      }
    } else {
      addArtistToQueue(artist.name);
    }
    setIsSearching(false);
  }, [addArtistToQueue, trackArtistProfileGeneration, handleError]);

  const handleSubmit = useCallback((artistName: string) => {
    setIsSearching(true);
    setError(null);
    setCopied(false);
    setSelectedArtist(null);
    setFetchedArtists([]);
    setLastSearchedArtist(artistName);
    addArtistToQueue(artistName);
  }, [addArtistToQueue]);

  useEffect(() => {
    if (lastSearchedArtist && error) {
      trackArtistProfileGeneration(lastSearchedArtist, false);
    }
  }, [email, lastSearchedArtist, error, trackArtistProfileGeneration]);

  const copyToClipboard = useCallback(() => {
    if (selectedArtist && selectedArtist.artistUrl) {
      navigator.clipboard.writeText(`${process.env.REACT_APP_HOSTNAME}/a/${selectedArtist.artistUrl}`)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch(() => {
          setError("Failed to copy to clipboard. Please try again.");
        });
    }
  }, [selectedArtist]);

  const downloadQRCode = useCallback(() => {
    const svg = document.getElementById('qr-code');
    if (svg && selectedArtist) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `${selectedArtist.artistName}-QR.png`;
        downloadLink.href = pngFile;
        downloadLink.click();
      };
      img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }
  }, [selectedArtist]);

  return (
    <div className="max-w-4xl pt-6 pb-6 bg-coral-dark-blue rounded-lg shadow-lg text-white">
      <div className="mb-8">
        <h2 className="text-xl md:text-2xl font-extrabold mb-8">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
            GENERATE ARTIST PROFILE
          </span>
        </h2>
        <div className="mb-4">
          <div className="relative flex items-center">
            <input
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your e-mail (optional)"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-coral-pink focus:border-transparent text-black"
              data-lpignore="true"
            />
            <div className="ml-2" data-tooltip-id="emailTooltip">
              <Info className="h-5 w-5 text-white" />
            </div>
          </div>
          <Tooltip id="emailTooltip" place="top">
            We use this email to help you get the most from<br />
            wearecoral. For payment-related communications,<br />
            we&apos;ll use official channels (Instagram, Artist<br />
            Website) to reduce the risk of identity fraud.<br />
          </Tooltip>
        </div>
        <ArtistSearch
          onSelect={handleArtistSelect}
          onSubmit={handleSubmit}
          placeholder="Search for your artist name"
        />
      </div>

      {isSearching && (
        <div className="mt-4 p-4 bg-coral-dark-blue flex items-center justify-center animate-pulse">
          <Spinner className="mr-2 animate-spin text-coral-pink" />
          <span>Searching for artist... This may take up to 30 seconds as we connect to external services.</span>
        </div>
      )}

      {error && (
        <div className="mt-4 p-4 bg-coral-deep-blue border border-coral-pink text-coral-pink rounded-md flex items-center">
          <AlertCircle className="mr-2" />
          <span>{error}</span>
        </div>
      )}

      {fetchedArtists.length > 1 && (
        <div className="mt-4">
          <h3 className="text-white mb-2">Multiple artists found. Please select the correct one:</h3>
          <ul className="space-y-2">
            {fetchedArtists.map((artist) => (
              <li key={artist.artistId}>
                <button
                  onClick={() => setSelectedArtist(artist)}
                  className={`w-full p-2 rounded-md transition-colors duration-200 ${
                    selectedArtist === artist
                      ? 'bg-coral-pink text-white'
                      : 'bg-coral-deep-blue text-white hover:bg-coral-pink hover:text-white'
                  }`}
                >
                  {artist.artistName} {artist.disambiguation && `(${artist.disambiguation})`}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedArtist && selectedArtist.artistUrl && (
        <div className="mt-6">
          <h3 className="text-2xl font-bold mb-6 text-center text-white">
            {selectedArtist.artistName}
            {selectedArtist.disambiguation && (
              <span className="text-sm font-normal ml-2">({selectedArtist.disambiguation})</span>
            )}
          </h3>
          <div className="space-y-6">
            <div className="rounded-md transition-all duration-300 ease-in-out">
              <div className="flex items-center mb-4">
                <input
                  type="text"
                  value={`${process.env.REACT_APP_HOSTNAME}/a/${selectedArtist.artistUrl}`}
                  readOnly
                  className="flex-grow px-4 py-2 border border-coral-pink rounded-l-md bg-coral-darker-blue text-gray-700"
                />
                <button
                  onClick={copyToClipboard}
                  className="px-4 py-3 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-r-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out h-full flex items-center justify-center"
                >
                  {copied ? <Check size={18} /> : <Copy size={18} />}
                </button>
              </div>
              {copied && (
                <p className="text-gray-200 mb-4 text-center animate-fade-in-down">URL copied to clipboard!</p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="p-4 border border-dashed border-coral-pink rounded-md text-center">
                <QRCodeSVG
                  id="qr-code"
                  value={`${process.env.REACT_APP_HOSTNAME}/a/${selectedArtist.artistUrl}`}
                  size={200}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                  className="mx-auto mb-4"
                />
                <button
                  onClick={downloadQRCode}
                  className="px-4 py-2 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out flex items-center justify-center mx-auto"
                >
                  <Download size={20} className="mr-2" />
                  Download QR Code
                </button>
              </div>
              <div className="border border-dashed border-coral-pink rounded-md">
                <ArtistBrandedImageCreator />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArtistProfileTools;