import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import coralIcon from '../../assets/images/coral-wordmark.png';
import smallCoralIcon from '../../assets/images/coral-short-wordmark.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { AuthContext } from '../AuthContext/AuthContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface HeaderProps extends ComponentPropsWithoutRef<any> {
  navigationTextHandler: string;
}

const Header: React.FC<HeaderProps> = ({ navigationTextHandler, ...props }) => {
  const { setShowAuth, currentUser } = useContext(AuthContext);


  return (
    <header className={`bg-white`} {...props}>
      <div className="px-4 py-4 max-w-screen-xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4 sm:space-x-8">
          <Link to="/" className="flex items-center space-x-2">
            <img src={coralIcon} alt="Coral" className="mr-1 h-11 hidden sm:block" />
            <div className="flex flex-col">
              <img src={smallCoralIcon} alt="Small Coral" className="block sm:hidden h-11" />
            </div>
          </Link>
        </div>
        <div className="flex items-center space-x-4 sm:space-x-8">
          <Link to="/for-artists" className={`${navigationTextHandler} font-syne whitespace-nowrap`}>
            For Artists
          </Link>
          {!currentUser && (
            <button onClick={() => setShowAuth({ show: true, signIn: true })} className={`hidden font-syne md:inline ${navigationTextHandler}`}>Log In</button>
          )}
          <JoinCoralButton className="bg-gradient-to-r from-coral-pink to-coral-orange text-white px-2 py-1 md:px-4 md:py-2 rounded-full text-xs md:text-sm">
            <span className="md:hidden">Start</span>
            <span className="hidden md:inline">Sign Up</span>
          </JoinCoralButton>
        </div>
      </div>
    </header>
  );
};

export default Header;