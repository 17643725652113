import React, { useState, useRef, useEffect } from 'react';
import { AlertCircle, ArrowLeft, ArrowRight, Upload, Download, Share2, Info } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

const BASE_IMAGE_PATHS = [
  'https://dev.wearecoral.org/artist-coral-asset-1.png',
  'https://dev.wearecoral.org/artist-coral-asset-2.png',
  'https://dev.wearecoral.org/artist-coral-asset-3.png',
  'https://dev.wearecoral.org/artist-coral-asset-4.png'
];

const ArtistBrandedImageCreator = () => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [mergedImage, setMergedImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [currentBaseIndex, setCurrentBaseIndex] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [overlaySize, setOverlaySize] = useState<{ width: number, height: number } | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    loadOverlayImage();
  }, [currentBaseIndex]);

  useEffect(() => {
    if (uploadedImage) {
      mergeImages();
    }
  }, [uploadedImage, currentBaseIndex]);

  const loadOverlayImage = () => {
    const img = new Image();
    img.onload = () => setOverlaySize({ width: img.width, height: img.height });
    img.onerror = () => setError('Failed to load the overlay image.');
    img.src = BASE_IMAGE_PATHS[currentBaseIndex];
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUploadedImage(e.target?.result as string);
      reader.readAsDataURL(file);
    }
  };

  const mergeImages = () => {
    if (!uploadedImage || !overlaySize) {
      setError('Please upload an image and ensure the overlay has loaded.');
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) {
      setError('Canvas context not available.');
      return;
    }

    const uploadedImg = new Image();
    uploadedImg.onload = () => {
      canvas.width = overlaySize.width;
      canvas.height = overlaySize.height;

      const scale = overlaySize.width / uploadedImg.width;
      const scaledWidth = overlaySize.width;
      const scaledHeight = uploadedImg.height * scale;
      
      ctx.drawImage(uploadedImg, 0, 0, scaledWidth, scaledHeight);

      const overlayImg = new Image();
      overlayImg.onload = () => {
        ctx.drawImage(overlayImg, 0, 0, overlaySize.width, overlaySize.height);
        setMergedImage(canvas.toDataURL('image/png'));
      };
      overlayImg.src = BASE_IMAGE_PATHS[currentBaseIndex];
    };
    uploadedImg.src = uploadedImage;
  };

  const downloadMergedImage = () => {
    if (mergedImage) {
      const link = document.createElement('a');
      link.href = mergedImage;
      link.download = 'coral-artist-promo.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const shareMergedImage = async () => {
    if (mergedImage && 'share' in navigator) {
      try {
        const blob = await fetch(mergedImage).then(r => r.blob());
        const file = new File([blob], 'coral-artist-promo.png', { type: 'image/png' });
        await navigator.share({
          files: [file],
        });
      } catch (error) {
        console.error('Error sharing:', error);
        setError('Failed to share the image.');
      }
    } else {
      setError('Sharing is not supported on this device.');
    }
  };

  const changeBaseImage = (direction: 'prev' | 'next') => {
    setCurrentBaseIndex((prevIndex) => {
      if (direction === 'prev') {
        return prevIndex > 0 ? prevIndex - 1 : BASE_IMAGE_PATHS.length - 1;
      } else {
        return (prevIndex + 1) % BASE_IMAGE_PATHS.length;
      }
    });
  };

  return (
    <div className="p-3 bg-coral-dark-blue rounded-md transition-all duration-300 ease-in-out text-white">
      <h3 className="font-semibold text-xl mb-4 text-center">
        <span className="">
          Generate Instagram Assets
        </span>
      </h3>
      
      <p className="text-gray-300 mb-4 text-center">
        Let your community know you are on coral, by generating and sharing an image to Instagram.
      </p>
      
      <div className="mb-4">
        <label htmlFor="image-upload" className="w-full px-4 py-3 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out flex items-center justify-center cursor-pointer">
          <Upload size={20} className="mr-2" />
          Upload Photo
        </label>
        <input
          id="image-upload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="hidden"
        />
      </div>

      <div className="mb-4 p-4 bg-coral-darker-blue rounded-md">
        <h4 className="text-lg font-semibold mb-2 flex items-center">
          <Info size={20} className="mr-2 text-coral-pink" />
          Guidelines for Best Results
        </h4>
        <ul className="list-disc list-inside text-sm text-gray-300">
          <li>Add your artist url to your bio or Linktree</li>
          <li>Tag @wearecoral in your post</li>
          <li>Use square images</li>
          <li>Centre main subject</li>
          
        </ul>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-coral-deep-blue border border-coral-pink text-coral-pink rounded-md flex items-center">
          <AlertCircle className="mr-2" />
          <span>{error}</span>
        </div>
      )}

      {mergedImage && (
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <button 
              onClick={() => changeBaseImage('prev')} 
              className="p-2 bg-coral-pink rounded-full hover:bg-coral-orange transition-colors duration-300"
              data-tooltip-id="prevTooltip"
            >
              <ArrowLeft size={24} />
            </button>
            <h4 className="text-lg font-semibold">Image Style {currentBaseIndex + 1}</h4>
            <button 
              onClick={() => changeBaseImage('next')} 
              className="p-2 bg-coral-pink rounded-full hover:bg-coral-orange transition-colors duration-300"
              data-tooltip-id="nextTooltip"
            >
              <ArrowRight size={24} />
            </button>
          </div>
          <Tooltip id="prevTooltip" place="top" content="Previous style" />
          <Tooltip id="nextTooltip" place="top" content="Next style" />
          <img src={mergedImage} alt="Coral Promo" className="w-full h-auto rounded-md" />
          <div className="mt-4 flex space-x-2">
            {!isMobile && (
              <button
                onClick={downloadMergedImage}
                className="w-full px-4 py-3 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out flex items-center justify-center"
              >
                <Download size={20} className="mr-2" />
                Download Image
              </button>
            )}
            {isMobile && (
              <button
                onClick={shareMergedImage}
                className="w-full px-4 py-3 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out flex items-center justify-center"
              >
                <Share2 size={20} className="mr-2" />
                Share Image
              </button>
            )}
          </div>
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default ArtistBrandedImageCreator;