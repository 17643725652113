import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalSubscribeButton from "../../components/PayPal/PayPalSubscribeButton";
import { getCoralFromAPI, getPayPalPlanIdForPledgeAmount } from '../../api/api';
import { BaseCoral, ClientCoral } from "shared/types/platformTypes";
import Image0 from 'images/thumbnails/image_0.png';
import Image1 from 'images/thumbnails/image_1.png';
import Image2 from 'images/thumbnails/image_2.png';
import Image3 from 'images/thumbnails/image_3.png';
import Image4 from 'images/thumbnails/image_4.png';
import Image5 from 'images/thumbnails/image_5.png';
import Image6 from 'images/thumbnails/image_6.png';
import Image7 from 'images/thumbnails/image_7.png';
import Image8 from 'images/thumbnails/image_8.png';
import Image9 from 'images/thumbnails/image_9.png';
import Image10 from 'images/thumbnails/image_10.png';
import Image11 from 'images/thumbnails/image_11.png';
import { BiArrowBack } from 'react-icons/bi';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../components/AuthContext/AuthContext';

const initialOptions = {
  currency: "AUD",
  vault: true,
  intent: 'subscription'
};

const imageArray = [
  Image0, Image1, Image2, Image3, Image4, Image5,
  Image6, Image7, Image8, Image9, Image10, Image11,
];

const getImageIndex = (name: string) => {
  let total = 0;
  name = name ?? "";
  for (let i = 0; i < name.length; i++) {
    total += name.charCodeAt(i);
  }
  return total % imageArray.length;
};

const Payments: React.FC = () => {
  const { userProfile } = useAuthContext();
  const location = useLocation();
  const { id } = useParams<{ id?: string, amount?: string }>();
  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get('amt') ?? undefined;
  const subscriptionId = searchParams.get('sid') ?? undefined;
  const history = useHistory();
  const [planId, setPlanId] = useState<string | null>(null);
  const [coral, setCoral] = useState<BaseCoral | null>(null);
  const [loading, setLoading] = useState(true);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  useEffect(() => {
    console.log('checking if id exists');
    if (id) {
      console.log('getting coral from API');

      (async () => {
        let data: ClientCoral;

        if (amount && !isNaN(parseFloat(amount))) {
          data = {
            pledgeAmount: parseFloat(amount),
            guid : id
          } as ClientCoral;
        } else {
          if (!userProfile) {
            throw new Error("If the payment amount is not specified the user must be logged in");
          }
          data = await getCoralFromAPI(id);
        }

        setCoral(data);
      })();
    }
  }, [id, amount]);

  useEffect(() => {
    console.log('checking if coral exists');
    if (coral) {
      const fetchPlanId = async () => {

        const data = await getPayPalPlanIdForPledgeAmount(coral.pledgeAmount);
        setPlanId(data.planId);
      };

      console.log('fetching plan id');

      fetchPlanId();
      setLoading(false);

      if (userProfile) {
        trackEvent(EventName.CREATE_CORAL_GOTO_PAYMENT, {
          userId: userProfile!.userId,
          coralId: coral.guid,
          amount: coral.pledgeAmount
        });
      }
    }
  }, [coral]);

  const handleBackClick = () => {
    history.push(`/coral/${id}`);
  };

  if (!id) {
    return <div className="text-center text-gray-600 font-semibold">No Coral ID found</div>;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!coral) {
    return <div className="text-center text-gray-600 font-semibold">Coral not found</div>;
  }

  const totalAmount = coral.pledgeAmount;
  const paymentProcessingFee = totalAmount * 0.029 + 0.30;
  const platformFeeIncludingGST = (totalAmount - paymentProcessingFee) * 0.10;
  const gst = platformFeeIncludingGST * 0.1; // Assuming GST is 10% of the platform fee
  const platformFeeExcludingGST = platformFeeIncludingGST - gst;
  const artistAmount = totalAmount - paymentProcessingFee - platformFeeIncludingGST;

  return (
    <div className="max-w-2xl mx-auto bg-white rounded-2xl shadow-lg overflow-hidden mt-8">
      <div className="p-6">
        <button
          onClick={handleBackClick}
          className="flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200 mb-4"
        >
          <BiArrowBack className="mr-2" />
          Back to Coral
        </button>
      </div>
      <div className="relative h-48 sm:h-64">
        <img
          src={imageArray[getImageIndex(coral.coralName)]}
          alt={`${coral.coralName} thumbnail`}
          className="absolute inset-0 w-full h-full object-cover object-center"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
        <h1 className="absolute bottom-4 left-6 text-3xl font-bold text-white">
          Complete Your Subscription
        </h1>
      </div>

      <div className="p-6 space-y-8">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">Subscription Details</h2>
          <p className="text-gray-600 mb-2">
            {coral.coralName
              ? `You're about to set up a monthly subscription to the coral ${coral.coralName}.`
              : "You're about to set up a monthly subscription to your coral."}
          </p>
          <p className="text-gray-600">
            {`wearecoral is built on trust based cooperation. You are not purchasing a good or service, 
            rather your regular contribution provides greater financial stability and space for the artists 
            you support to make more of the music you love. You are 
            welcome to stop or modify your subscription at any time.`}
          </p>
        </div>
        <div className="bg-gray-50 p-6 rounded-xl space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-lg font-medium text-gray-700">Total Monthly Pledge</span>
            <span className="text-2xl font-bold text-gray-900">${totalAmount.toFixed(2)}</span>
          </div>
          <div className="space-y-2 text-sm text-gray-500">
            <div className="flex justify-between">
              <span>Payment Processing Fee:</span>
              <span>${paymentProcessingFee.toFixed(2)} ({((paymentProcessingFee / totalAmount) * 100).toFixed(2)}%)</span>
            </div>
            <div className="flex justify-between">
              <span>Platform Fee:</span>
              <span>${platformFeeExcludingGST.toFixed(2)} ({((platformFeeExcludingGST / totalAmount) * 100).toFixed(2)}%)</span>
            </div>
            <div className="flex justify-between">
              <span>GST on Platform Fee:</span>
              <span>${gst.toFixed(2)} ({((gst / totalAmount) * 100).toFixed(2)}%)</span>
            </div>
          </div>
          <div className="flex justify-between items-center pt-2 border-t border-gray-200">
            <span className="font-medium text-gray-700">Amount to Artists:</span>
            <span className="text-lg font-bold text-coral-pink">${artistAmount.toFixed(2)}</span>
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">Payment Method</h2>
          <p className="text-gray-600 mb-4">
            We use PayPal to process your subscription securely. You&apos;ll be redirected to PayPal to complete your payment.
          </p>
          <div className="flex items-center space-x-2 mb-4">
            <div className="relative flex items-center">
              <input
                type="checkbox"
                id="terms"
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 cursor-pointer"
              />
              <label
                htmlFor="terms"
                className="ml-2 text-sm text-gray-700 cursor-pointer select-none"
              >
                I have read, understood, and accepted the 
                <a 
                  href="https://www.wearecoral.org/terms" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-blue-500 hover:underline"
                >
                  {" "}Terms and Conditions
                </a>.
              </label>
            </div>
          </div>
          {planId && (
            agreedToTerms ? (
              <>
                {process.env.REACT_APP_PAYPAL_CLIENT_ID ? (
                  <PayPalScriptProvider options={{ ...initialOptions, clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
                    <PayPalSubscribeButton planId={planId} coralId={id} subscriptionId={subscriptionId} />
                  </PayPalScriptProvider>
                ) : (
                  <div>Error: REACT_APP_PAYPAL_CLIENT_ID is undefined.</div>
                )}
              </>
            ) : (
              <button
                className="w-full py-2 px-4 bg-gray-300 text-gray-500 rounded-md cursor-not-allowed"
                disabled
              >
                Please agree to the Terms and Conditions
              </button>
            )
          )}
        </div>

        <div className="text-sm text-gray-500 space-y-2">
          <h3 className="font-semibold text-gray-700">Terms and Conditions:</h3>
          <ul className="list-disc pl-5 space-y-1">
            <li>Your subscription will be processed monthly on the date of your initial payment.</li>
            <li>Funds are held until the end of each month before being distributed to artists.</li>
            <li>You can cancel or modify your subscription at any time through your account settings.</li>
          </ul>
          <p>
            By proceeding, you agree to wearecoral&apos;s{' '}
            <a href="https://www.wearecoral.org/terms" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Terms of Service</a> and{' '}
            <a href="https://www.wearecoral.org/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Privacy Policy</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Payments;