import React, { useState, useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { ArtistSearchResult } from 'shared/types/platformTypes';
import { searchArtists } from '../../api/api';
import Spinner from '../Spinner/Spinner';
import { Search } from 'lucide-react';

interface ArtistSearchProps {
  onSelect: (artist: ArtistSearchResult) => void;
  onSubmit: (artistName: string) => void;
  placeholder?: string;
}

const ArtistSearch: React.FC<ArtistSearchProps> = ({ onSelect, onSubmit, placeholder = "Search for an artist" }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<ArtistSearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>();

  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const searchArtistsFromAPI = useCallback(async (artistName: string) => {
    if (artistName.length < 2) return;
    setIsLoading(true);
    setError(null);
    try {
      const data = await searchArtists(artistName);
      if (data && Array.isArray(data.artists)) {
        setResults(data.artists);
        setLastEvaluatedKey(data.lastEvaluatedKey);
        setShowDropdown(true);
      } else {
        console.error('Unexpected API response structure:', data);
        setError('Received unexpected data format from the server.');
        setResults([]);
      }
    } catch (error) {
      console.error('Error searching artists:', error);
      setError('Failed to fetch artists. Please try again.');
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedSearch = useRef(debounce(searchArtistsFromAPI, 300)).current;

  useEffect(() => {
    if (query.length >= 2) {
      debouncedSearch(query);
    } else {
      setResults([]);
      setShowDropdown(false);
    }
  }, [query, debouncedSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setSelectedIndex(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex(prev => (prev < results.length ? prev + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex(prev => (prev > 0 ? prev - 1 : results.length));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedIndex >= 0 && selectedIndex < results.length) {
        handleSelect(results[selectedIndex]);
      } else if (selectedIndex === results.length) {
        handleWidenSearch();
      }
    }
  };

  const handleSelect = (artist: ArtistSearchResult) => {
    onSelect(artist);
    resetComponent();
  };

  const handleWidenSearch = () => {
    onSubmit(query);
    resetComponent();
  };

  const resetComponent = () => {
    setQuery('');
    setResults([]);
    setShowDropdown(false);
    setSelectedIndex(-1);
    setLastEvaluatedKey(undefined);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const loadMore = async () => {
    if (lastEvaluatedKey) {
      setIsLoading(true);
      try {
        const data = await searchArtists(query, 10, lastEvaluatedKey);
        if (data && Array.isArray(data.artists)) {
          setResults(prevResults => [...prevResults, ...data.artists]);
          setLastEvaluatedKey(data.lastEvaluatedKey);
        } else {
          console.error('Unexpected API response structure:', data);
          setError('Received unexpected data format from the server.');
        }
      } catch (error) {
        console.error('Error loading more artists:', error);
        setError('Failed to load more artists. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="relative text-gray-700">
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-coral-pink focus:border-transparent"
      />
      {isLoading && <div className="absolute right-3 top-3"><Spinner /></div>}
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
      {showDropdown && query.length >= 2 && (
        <ul ref={dropdownRef} className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {results.map((artist, index) => (
            <li
              key={artist.guid}
              className={`px-4 py-2 cursor-pointer text-sm hover:bg-gray-100 ${index === selectedIndex ? 'bg-gray-200' : ''}`}
              onClick={() => handleSelect(artist)}
            >
              {artist.name}
              {artist.country && <span className="text-gray-500 ml-2">({artist.country})</span>}
              {artist.disambiguation && <span className="text-gray-500 ml-2">- {artist.disambiguation}</span>}
              {artist.artistType && <span className="text-gray-500 ml-2">[{artist.artistType}]</span>}
            </li>
          ))}
          {lastEvaluatedKey && (
            <li className="px-4 py-2 text-center text-coral-pink cursor-pointer hover:bg-gray-100" onClick={loadMore}>
              Load more
            </li>
          )}
          <li
            className={`px-4 py-2 cursor-pointer text-sm hover:bg-gray-100 flex items-center ${results.length === selectedIndex ? 'bg-gray-200' : ''}`}
            onClick={handleWidenSearch}
          >
            <Search className="mr-2" size={18} />
            <span>Expand search for &#34;{query}&#34;</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ArtistSearch;