import React, { useState, useEffect, useCallback } from 'react';
import GenericCoralModal from '../GenericCoralModal/GenericCoralModal';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import CoralVisualisation from '../CoralVisualisation/CoralVisualisation';

interface Contribution {
  artistName: string;
  amount: number;
  topGenre: string;
  topCategory: string;
  transactionId: string;
}

interface CoralData {
  transactionId: string;
  totalAmount: number;
  genres: { name: string; amount: number }[];
  artists: { name: string; amount: number }[];
}

const RecentCoralVisualisation: React.FC = () => {
  const [coralData, setCoralData] = useState<CoralData[]>([]);
  const [currentCoralIndices, setCurrentCoralIndices] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchContributions = useCallback(async () => {
    try {
      const stage = process.env.REACT_APP_ENVIRONMENT || 'dev';
      const url = `https://wearecoral-public-data-${stage}.s3.ap-southeast-2.amazonaws.com/recent-contributions.json`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch contributions');
      }
      const data: Contribution[] = await response.json();
      const processedData = processContributions(data);
      setCoralData(processedData);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching contributions:', err);
      setError('Failed to load recent contributions');
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchContributions();
  }, [fetchContributions]);

  useEffect(() => {
    if (coralData.length > 1) {
      const getRandomIndices = () => {
        const newIndices: number[] = [];
        const availableIndices = coralData.map((_, index) => index)
          .filter(index => !currentCoralIndices.includes(index));
        
        for (let i = 0; i < 2; i++) {
          if (availableIndices.length === 0) break;
          const randomIndex = Math.floor(Math.random() * availableIndices.length);
          newIndices.push(availableIndices[randomIndex]);
          availableIndices.splice(randomIndex, 1);
        }

        return newIndices;
      };

      const refreshCorals = () => {
        const newIndices = getRandomIndices();
        if (newIndices.length === 2) {
          setCurrentCoralIndices(newIndices);
        }
      };

      // Initial random selection
      refreshCorals();

      const interval = setInterval(refreshCorals, 5000);

      return () => clearInterval(interval);
    }
  }, [coralData]);

  const processContributions = (contributions: Contribution[]): CoralData[] => {
    const coralMap = new Map<string, CoralData>();

    contributions.forEach((contribution) => {
      if (!coralMap.has(contribution.transactionId)) {
        coralMap.set(contribution.transactionId, {
          transactionId: contribution.transactionId,
          totalAmount: 0,
          genres: [],
          artists: [],
        });
      }

      const coral = coralMap.get(contribution.transactionId)!;
      coral.totalAmount += contribution.amount;

      const genreIndex = coral.genres.findIndex((g) => g.name === contribution.topCategory);
      if (genreIndex === -1) {
        coral.genres.push({ name: contribution.topCategory, amount: contribution.amount });
      } else {
        coral.genres[genreIndex].amount += contribution.amount;
      }

      const artistIndex = coral.artists.findIndex((a) => a.name === contribution.artistName);
      if (artistIndex === -1) {
        coral.artists.push({ name: contribution.artistName, amount: contribution.amount });
      } else {
        coral.artists[artistIndex].amount += contribution.amount;
      }
    });

    return Array.from(coralMap.values());
  };

  const openModal = () => {
    setIsModalOpen(true);
    trackEvent(EventName.VIEW_ALL_CONTRIBUTIONS_INTERACTION, {});
  };

  const closeModal = () => setIsModalOpen(false);

  if (isLoading) {
    return <div className='text-custom-gray font-semibold text-xs'>Loading recent corals...</div>;
  }

  if (error) {
    return <div className='text-custom-gray font-semibold text-xs'>{error}</div>;
  }

  if (coralData.length === 0) {
    return null;
  }

  return (
    <div className="bg-white bg-opacity-90 shadow-lg rounded-xl px-4 py-6 max-w-fit mx-auto">
      <div className="inline-block min-w-fit text-center">
        <p className="text-lg sm:text-xl font-nunito text-coral-black font-bold text-center mb-4">
          Recent Corals
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {currentCoralIndices.length === 2 && (
            <>
              <CoralVisualisation coral={coralData[currentCoralIndices[0]]} />
              <div className="hidden lg:block">
                <CoralVisualisation coral={coralData[currentCoralIndices[1]]} />
              </div>
            </>
          )}
        </div>
        <p className="mt-4 text-xs text-gray-400">
          Recent corals powering independent music.
        </p>
        <p>
          <button 
            onClick={openModal}
            className="mt-1 text-xs text-coral-pink hover:underline focus:outline-none"
          >
            View all coral contributions, ever
          </button>
        </p>

        <GenericCoralModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="block md:hidden">
              <iframe 
              src="https://cdn.forms-content-1.sg-form.com/a7a097eb-4252-11ef-9b81-4a03a5e66761"
              title="Contributions Form (Mobile)"
              className="w-full h-full min-h-[550px]"
              />
          </div>
          <div className="hidden md:block">
              <iframe 
              src="https://cdn.forms-content-1.sg-form.com/ef7831be-41b0-11ef-86c0-3e26e4945dab"
              title="Contributions Form"
              className="w-full h-full min-h-[550px]"
              />
          </div>
        </GenericCoralModal>
      </div>
    </div>
  );
};

export default RecentCoralVisualisation;