import { useState, useEffect, useRef, useCallback } from 'react';
import { getCoralFromAPI, getSharedArtistPoolCoralDetails, getArtistDetailsByGuid, getArtistDetailsPublic } from '../api/api';
import { ClientCoral, ClientArtistPool, CoralType, Source, SourceType, ClientArtistListItem, BlendedArtistListItem } from 'shared/types/platformTypes';
import { processSingleArtistSelection } from 'shared/utils/coralProcessingUtils';
import { addArtist } from './ArtistListHelperFunctions';
import useArtistFetchQueue from './useArtistFetch';
import { normalizeName } from 'shared/utils/nameNormalization';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../components/AuthContext/AuthContext';

const useCoralEditorArtistManagement = (
    coralData: ClientCoral | null, 
    activeFilters: Array<{ filterType: 'genre' | 'location'; value: string }>, 
    setCoralData: (updateFunction: (prevData: ClientCoral) => ClientCoral) => void,
  ) => {
    const { userProfile } = useAuthContext();
    const [topArtists] = useState<ClientArtistPool | null>(null);
    const [isDynamicArtistPools, setIsDynamicArtistPools] = useState(false);
    const [uniqueGenres, setUniqueGenres] = useState<{ value: string; count: number; }[]>([]); 
    const [uniqueLocations, setUniqueLocations] = useState<{ value: string; count: number; }[]>([]);
    const [dynamicArtistPools, setDynamicArtistPools] = useState<{ sourceType: SourceType; sourceArtistPoolGuid: string; sourceArtistPoolCoralName: string }[]>([]);
    const [sourceArtistPoolGuids, setSourceArtistPoolGuids] = useState<string[]>([]);

    useEffect(() => {
        // Set the variable dynamicArtistPools with the unique set of connected artist pools. Includes both the sourceType and artistPoolGuid
        if (coralData && coralData.artistPool) {
            const pairSet = new Set();
            const artistPoolDetails: { sourceType: SourceType; sourceArtistPoolGuid: string; }[] = [];
            const guids = new Set<string>();

            for (const { source, sourceType, sourceArtistPoolGuid } of coralData.artistPool.artistList) {
                if (source !== Source.USER) {
                    const pairString = JSON.stringify({ sourceType, sourceArtistPoolGuid });

                    if (!pairSet.has(pairString)) {
                        pairSet.add(pairString);
                        artistPoolDetails.push({ sourceType, sourceArtistPoolGuid });
                    }

                    if (sourceArtistPoolGuid) {
                        guids.add(sourceArtistPoolGuid);
                    }
                }
            }

            Promise.all(artistPoolDetails.map(detail => getSharedArtistPoolCoralDetails(detail.sourceArtistPoolGuid)))
                .then(coralDetailsList => {
                    const updatedDetails = artistPoolDetails.map((detail, index) => ({
                        ...detail,
                        sourceArtistPoolCoralName: coralDetailsList[index].coralName
                    }));
                    setDynamicArtistPools(updatedDetails);
                    setSourceArtistPoolGuids(Array.from(guids));
                })
                .catch(error => {
                    console.error('Error fetching coral details:', error);
                });
        }
    }, [coralData]);

    useEffect(() => {
        // Update isDynamicArtistPools whenever coralData changes
        if (coralData && coralData.artistPool && coralData.artistPool.artistList) {
            setIsDynamicArtistPools(coralData.artistPool.artistList.some(artist => artist.source !== Source.USER));
        }
    }, [coralData]);

    useEffect(() => {
        if (coralData && coralData.artistPool && coralData.artistPool.artistList) {
            const genres = coralData.artistPool.artistList.flatMap(artist => artist.artistData?.genres || []);
            const counts = genres
                .filter(genre => genre !== '')
                .reduce((acc: Record<string, number>, genre) => ({ ...acc, [genre]: (acc[genre] || 0) + 1 }), {});
            const result = Object.entries(counts)
                .map(([value, count]) => ({ value, count }))
                .sort((a, b) => b.count - a.count);
            setUniqueGenres(result);
        }
    }, [coralData]);

    useEffect(() => {
        if (coralData && coralData.artistPool && coralData.artistPool.artistList) {
            const locations = coralData.artistPool.artistList.map(artist => artist.artistData?.country || '');
            const counts = locations
                .filter(location => location !== '')
                .reduce((acc: Record<string, number>, location) => ({ ...acc, [location]: (acc[location] || 0) + 1 }), {});
            const result = Object.entries(counts)
                .map(([value, count]) => ({ value, count }))
                .sort((a, b) => b.count - a.count);
            setUniqueLocations(result);
        }
    }, [coralData]);

    // Process artist selection criteria when dynamic filters change
    useEffect(() => {
        if (coralData && coralData.artistPool) {
            const updatedArtistPool = {
                ...coralData.artistPool,
                artistList: coralData.artistPool.artistList.map(artist => {
                    const isSelected = processSingleArtistSelection(artist, activeFilters);
                    return { ...artist, selected: isSelected };
                })
            };

            const newCoralData = {
                ...coralData,
                artistPool: updatedArtistPool
            };

            if (JSON.stringify(coralData) !== JSON.stringify(newCoralData)) {
                setCoralData(() => newCoralData);
                console.log(`CoralData has been updated due to changes in active filters.`); 
            }
        }
    }, [activeFilters, coralData]);

    const updateCoralData = (updatedArtistList: ClientArtistListItem[]) => {
        // const updatedCoralData = coralData as ClientCoral;

        setCoralData(prevCoralData => ({
            ...prevCoralData,
            artistPool: {
                ...prevCoralData.artistPool,
                artistList: updatedArtistList,
            },
        }));
        console.log(`CoralData has been updated in updateCoralData.`); 
      };    

      const updateArtistInCoralData = useCallback((fetchedArtists: BlendedArtistListItem[]) => {
        // Set the updated Coral data with the fetched artists information
        setCoralData((prevCoralData: ClientCoral) => {
            // Filter out artists that do not have an artistId (pending artists)
            const pendingArtists = prevCoralData.artistPool.artistList.filter(artist => !artist.artistId);
            console.log('Pending artists:', pendingArtists); // Log the pending artists for debugging
            
            // Map over pending artists to update them with fetched artists data
            const updatedArtists = pendingArtists.flatMap(pendingArtist => {
                // Find matching fetched artists by name
                const matchingFetchedArtists = fetchedArtists.filter(
                    fetchedArtist => normalizeName(fetchedArtist.artistName!) === normalizeName(pendingArtist.artistName!)
                );
                console.log(`Matching fetched artists for ${pendingArtist.artistName}:`, matchingFetchedArtists); // Log matching fetched artists for debugging
    
                // If there are matching fetched artists, map over them to update the pending artist's data
                if (matchingFetchedArtists.length > 0) {
                    return matchingFetchedArtists.map(detail => ({
                        ...pendingArtist,
                        artistData: {
                            ...pendingArtist.artistData,
                            ...detail.artistData,
                            name: detail.artistName || '',
                        },
                        artistId: detail.artistId,
                        artistName: detail.disambiguation
                            ? `${detail.artistName} (${detail.disambiguation})`
                            : detail.artistName,
                        artistListItemStatus: detail.artistListItemStatus === 'disambiguation' ? 'active' : detail.artistListItemStatus,
                        artistListItemActive: detail.artistListItemStatus === 'disambiguation' ? true : detail.artistListItemActive,
                        selected: processSingleArtistSelection(detail),
                    }));
                }
                // If no matching fetched artists, return the pending artist as is
                return [pendingArtist];
            });
    
            // Filter out artists that are not pending (already have an artistId)
            const nonPendingArtists = prevCoralData.artistPool.artistList.filter(artist => 
                !pendingArtists.some(pendingArtist => pendingArtist.artistName === artist.artistName)
            );
            console.log('Non-pending artists:', nonPendingArtists); // Log non-pending artists for debugging
    
            // Combine updated artists with non-pending artists to form the updated artist list
            const updatedArtistList = [...updatedArtists, ...nonPendingArtists];
    
            console.log('Updated artist list:', updatedArtistList); // Log the updated artist list for debugging
    
            // Return the updated Coral data with the new artist list
            return {
                ...prevCoralData,
                artistPool: {
                    ...prevCoralData.artistPool,
                    artistList: updatedArtistList
                }
            };
        });
    }, [setCoralData]);
    
    const onArtistsFetched = useCallback((fetchedArtists: BlendedArtistListItem[]) => {
        console.log('onArtistsFetched called with:', fetchedArtists);
        updateArtistInCoralData(fetchedArtists);
    }, [updateArtistInCoralData]);
    
    const { addArtistToQueue } = useArtistFetchQueue(onArtistsFetched);

    const coralDataRef = useRef(coralData);
    
    useEffect(() => {
        coralDataRef.current = coralData;
    }, [coralData]);


    /**
     * When a user directly adds an artist by their name
     * 
     * @param newArtistName - The name of the new artist to be added.
     * If the coral data type is USER and the artist does not already exist in the artist pool,
     * a new artist with the given name, a status of 'pending', and a source of USER is added to the artist pool.
     */
    const addArtistDirectly = async (newArtistName: string, artistGuid?: string) => {
        trackEvent(EventName.ADD_ARTIST_TO_CORAL, {
            userId: userProfile?.userId || 'n/a',
            method: 'direct'
        });
    
        if (coralData?.type === CoralType.USER) {
            const existingArtist = coralData.artistPool.artistList.find((artist: ClientArtistListItem) => 
                (artistGuid && artist.artistId === artistGuid) || artist.artistName === newArtistName
            );
    
            if (!existingArtist) {
                const newArtist: ClientArtistListItem = {
                    artistId: '',
                    artistName: newArtistName,
                    artistListItemStatus: 'pending',
                    source: Source.USER,
                    sourceArtistPoolGuid: '',
                    sourceType: SourceType.USER_ADDED,
                    selected: true,
                    artistListItemActive: true,
                    payoutStatus: '',
                };
    
                setCoralData(prevCoralData => ({
                    ...prevCoralData,
                    artistPool: {
                        ...prevCoralData.artistPool,
                        artistList: addArtist(prevCoralData.artistPool.artistList, newArtist),
                    },
                }));
    
                if (artistGuid) {
                    try {
                        const artistDetails = userProfile?.userId
                            ? await getArtistDetailsByGuid(artistGuid)
                            : await getArtistDetailsPublic(undefined, undefined, artistGuid);
                        updateArtistInCoralData(artistDetails);
                    } catch (error) {
                        console.error('Error fetching artist details:', error);
                    }
                } else {
                    addArtistToQueue(newArtistName);
                }
            }
        }
    };

    /**
     * Used when a user adds an artist from a dynamic artist pool (i.e. suggested artists)
     * 
     * @param artistId - The id of the new artist to be added.
     * @param artistName - The name of the new artist to be added.
     * If the coral data type is USER and the artist does not already exist in the artist pool,
     * a new artist with the given id and name, a status of 'added', and a source of USER is added to the artist pool.
     */
    const addArtistFromDynamicArtistPool = (artist: ClientArtistListItem) => {
        trackEvent(EventName.ADD_ARTIST_TO_CORAL, {
            userId: userProfile!.userId,
            method: 'pool'
        });

        if (coralData?.type === CoralType.USER) {
            const updatedCoralData = coralData as ClientCoral;
            const existingArtist = updatedCoralData.artistPool.artistList.find(a => a.artistId === artist.artistId && a.source === Source.USER);

            if (!existingArtist) {
                const updatedArtists = updatedCoralData.artistPool.artistList;

                const newArtist: ClientArtistListItem = {
                    ...artist,
                    source: Source.USER,
                    sourceArtistPoolGuid: '',
                    sourceType: SourceType.USER_ADDED,
                    selected: true,
                    artistListItemActive: artist.artistListItemActive || true,
                };

                const updatedArtistList = addArtist(updatedArtists, newArtist);
                console.log(`CoralData has been updated in addArtistFromDynamicArtistPool.`); 
                updateCoralData(updatedArtistList);
            }
        }
    };

    /**
     * Removes all artists from the artist pool that have a sourceArtistPoolGuid equal to the parameter.
     * 
     * @param sourceArtistPoolGuid - The guid of the source artist pool.
     * If the artist pool exists, all artists with the given sourceArtistPoolGuid are removed from the artist pool.
     */
    const removeArtistsBySourceArtistPoolGuid = (sourceArtistPoolGuid: string) => {
        console.log(`removeArtistsBySourceArtistPoolGuid called with sourceArtistPoolGuid: ${sourceArtistPoolGuid}`);

        if (coralData?.artistPool?.artistList) {
            const updatedArtists = coralData.artistPool.artistList.filter(artist => {
                return artist.sourceArtistPoolGuid !== sourceArtistPoolGuid;
            });

        setCoralData(prevCoralData => ({
            ...prevCoralData,
            artistPool: {
                ...prevCoralData.artistPool,
                artistList: updatedArtists,
            },
        }));
            console.log(`CoralData has been updated in removeArtistsBySourceArtistPoolGuid.`); 
        }
    };

    /**
     * Removes an artist from the artist pool.
     * The behaviour differs based on the artist context:
     * - If the arist source = User, the artist is deleted from the pool.
     * - If the artistStatus = 'added' the status is set to 'ok'
     * - If the artistStatus = 'filtered' then the status is set to 'removed'
     * 
     * @param artistName - The name of the artist to be removed.
     * If the artist pool exists, the artist with the given name is removed from the artist pool.
     */
    const removeArtist = (artistName: string) => {
        trackEvent(EventName.REMOVE_ARTIST_FROM_CORAL, {
            userId: userProfile?.userId || 'n/a',
        });

        console.log(`Attempting to remove artist: ${artistName}`);
        if (coralData?.artistPool?.artistList) {
          let updatedArtists = coralData.artistPool.artistList;
          const artist = updatedArtists.find(a => a.artistName === artistName);
    
          if (artist && artist.source === Source.USER && artist.sourceType === SourceType.USER_ADDED && artist.artistListItemStatus !== 'pending') {
            const isConfirmed = confirm(`Are you sure you want to remove this selected artist, ${artistName}, from your coral?`);
            if (!isConfirmed) {
              console.log(`Removal of artist ${artistName} cancelled by user.`);
              return;
            }
          }
    
        if (updatedArtists.some(artist => artist.artistName === artistName && artist.source === Source.USER)) {
            console.log(`Artist ${artistName} found in user source. Removing from artist pool.`);
            updatedArtists = coralData.artistPool.artistList.filter(artist => {
                return !(artist.artistName === artistName && artist.source === Source.USER);
            });
            console.log(`Updated artist pool after removal: ${JSON.stringify(updatedArtists)}`);
        } else {
            console.log(`Artist ${artistName} not found in user source. Updating non-user source.`);
            updatedArtists = updatedArtists.map(artist => { // This is to handle artists added from dynamic user pools
                if (artist.artistName === artistName && artist.source !== Source.USER) {
                    console.log(`Artist ${artistName} found in non-user source. Setting active to false.`);
                    return { ...artist, active: false };
                }
                return artist;
            });
            console.log(`Artist ${artistName} updated in non-user source: ${JSON.stringify(updatedArtists)}`);
        }
    
          setCoralData(prevCoralData => ({
            ...prevCoralData,
            artistPool: {
              ...prevCoralData.artistPool,
              artistList: updatedArtists,
            },
          }));
          console.log(`CoralData has been updated in removeArtist.`);
        } else {
          console.log(`No artist list found in coralData.`);
        }
      };

    /**
     * Enables an artist that has been disabled / excluded in the artist pool.
     * 
     * @param artistName - The name of the artist to be enabled.
     * If the artist pool exists and the artist with the given name has an active status of false,
     * the active status of the artist is changed to true.
     */
    const enableArtist = (artistName: string) => {
        if (coralData?.artistPool?.artistList) {
            let updatedArtists = coralData.artistPool.artistList;

            updatedArtists = updatedArtists.map((artist: ClientArtistListItem) => {
                if (artist.artistName === artistName && !artist.artistData?.artistActive) {
                    return { ...artist, active: true };
                }
                return artist;
            });

            setCoralData(prevCoralData => ({
                ...prevCoralData,
                artistPool: {
                    ...prevCoralData.artistPool,
                    artistList: updatedArtists,
                },
            }));
            console.log(`CoralData has been updated in enableArtist.`); 
        }
    };

    const retrieveArtistsFromCoral = async (coralId: string) => {
        const retrievedCoral = await getCoralFromAPI(coralId);
    
        if (coralData && coralData.artistPool && retrievedCoral.artistPool) {
          const uniqueArtists = retrievedCoral.artistPool.artistList.filter(
            (retrievedArtist) =>
              !coralData.artistPool.artistList.find(
                (artist) =>
                  artist.artistId
                    ? artist.artistId === retrievedArtist.artistId
                    : artist.artistName === retrievedArtist.artistName
              )
          );
    
          setCoralData((prevCoralData) => ({
            ...prevCoralData,
            artistPool: {
              ...prevCoralData.artistPool,
              artistList: [
                ...(prevCoralData.artistPool.artistList || []),
                ...uniqueArtists.map((artist) => ({
                  artistId: artist.artistId,
                  artistName: artist.artistName,
                  artistListItemStatus: artist.artistListItemStatus,
                  source: artist.source,
                  sourceArtistPoolGuid: artist.sourceArtistPoolGuid,
                  sourceType: artist.sourceType,
                  artistData: artist.artistData,
                  selected: false,
                  artistListItemActive: artist.artistListItemActive,
                  payoutStatus: artist.payoutStatus,
                })),
              ],
            },
          }));
          console.log(`CoralData has been updated in retrieveArtistsFromCoral.`);
        }
    };    

    return {
        addArtistDirectly,
        removeArtist,
        removeArtistsBySourceArtistPoolGuid,
        enableArtist,
        addArtistFromDynamicArtistPool,
        retrieveArtistsFromCoral,
        isDynamicArtistPools,
        uniqueGenres,
        uniqueLocations,
        topArtists,
        dynamicArtistPools,
        sourceArtistPoolGuids,
    };
};

export default useCoralEditorArtistManagement;
