export const faqData = [
    {
        "question": "What is wearecoral?",
        "answer": `wearecoral is a platform dedicated to enriching the music ecosystem, focusing on fostering diverse styles and genres. The purpose 
        is to support artists in leading fulfilling lives, dedicated to creating music. Music enthusiasts can support their favourite artists and 
        scenes with monthly pledges. These contributions are distributed to artists based on selections made by users. This 
        direct support enables musicians to concentrate on their art, and enrich our lives with their creativity. Coral harnesses our collective
        power, allowing us to actively shape the musical cultures we cherish.`
    },
    {
        "question": "Does wearecoral replace streaming platforms like Spotify?",
        "answer": `No, wearecoral does not replace streaming platforms. It complements them. Platforms like Spotify provide incredible music accessibility 
        and discovery but do not provide sufficient financial stability for the diversity of artists that exist outside of the mainstream. 
        wearecoral is focused on direct financial support for artists. Coral grows the music ecosystem, and provides another means for artists to be supported
        in producing the music we enjoy.`
    },
    {
        "question": "How does wearecoral's unique model work?",
        "answer": `wearecoral has a unique approach that extends beyond popular creator platforms like Patreon and Substack. Members of the wearecoral community
        create one or more 'corals,' which disperse monthly contributions across multiple musicians. This dynamic 
        and multi-artist approach aligns with how music communities build around scenes and genres.`
    },
    {
        "question": "Does the artist need to be on wearecoral to receive contributions?",
        "answer": `No, wearecoral's unique model is built around communities stepping up to support the artists and sounds they value. Upon receiving a contribution,
        wearecoral will reach out to the artist to inform them of the support, inviting them to collect the funds. The artist will have 9 months to act, before the money
        is redistributed to other artists nominated by the user, or ultimately refunded.`
    },
    {
        "question": "How does wearecoral generate revenue?",
        "answer": `After transaction fees, wearecoral retains a platform fee of approximately 10% from member contributions. This fee is for the services and features 
        offered by the platform and wearecoral team. wearecoral is a business that strikes a balance between being profitable and purpose-driven. We commit to 
        reinvesting 50% of our platform fee profits back into the arts and music through various initiatives. This approach aligns our goals with the broader 
        music community and allows us to further support music.`
    },
    {
        "question": "OK, so how much should I pledge?",
        "answer": `The amount is up to you, but we suggest a monthly contribution equal to the cost of a single drink at a local gig.`
    },
    {
        "question": "Isn't there a cost of living crisis?",
        "answer": "Yes there sure is, and we all need to prioritise what’s important for us. If music is important in your life, consider contributing a small amount, once a month, to ensure the music you care about thrives. It'll mean we can continue to enjoy it together, for a long time."
    },
    {
        "question": "What benefits do I receive for my monthly contribution?",
        "answer": `Your pledge is an investment in music and its creators. In today's environment, many musicians work second jobs, or are 
        leaving the industry altogether. Your pledge can change this. It ensures artists are appreciated and 
        can dedicate themselves to their craft, enriching our lives. So rather than tangible goods or exclusive content, you're rewarded 
        with the cultural wealth of more music.

        We did consider exclusive artist content, but musicians have already created music that we love. Requiring them to become 
        'content creators' or take other steps feels unnecessary and a distraction from making music. 
        There are other platforms that successfully offer that approach. In the future we'd love to create collective perks like events that align with our community ethos.`
    },
    {
        "question": "Is this charity?",
        "answer": `This isn't charity as it's about the vitality and longevity of the music we care about. wearecoral sits in a novel space between transactional relationship and 
        charity. A useful metaphor is a picnic with friends. We don't take food based on contractual agreements. We take food, expect others to take food, and we enjoy the bounty together. This is
        how trust based cooperation works. We don't have many good examples of this at scale, and wearecoral is seeking to change that.`
    },    
    {
        "question": "What do artists use the contributions for?",
        "answer": `Artists can use the contribution to continue to focus on making music. This may mean giving up a second job unrelated to music, 
        buying gear, or even just covering rent or a mortgate. wearecoral enables communities to work together with artists to ensure they can focus on making 
        the music that we love and celebrate.`
    },
    {
        "question": "I'm still not convinced. Change my mind.",
        "answer": `We get it. Why not join, and create your own coral to explore how easily it is to support artists. This will give you a feel 
        for your impact on the artists and music scenes you support before making any commitment.`
    }    
];