import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserArtistConnections, updateUserArtistConnections } from '../../api/api';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import { getArtistDetails } from '../../api/api';
import { BlendedArtistListItem } from 'shared/types/platformTypes';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../components/AuthContext/AuthContext';

const ArtistConnections: React.FC = () => {
    const [artistConnections, setArtistConnections] = useState<{ artistName: string, artistGuid: string }[]>([]);
    const [newArtistName, setNewArtistName] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(false);
    const history = useHistory();
    const { userProfile } = useAuthContext();

    useEffect(() => {
        const fetchUserAndArtistConnections = async () => {
            try {
                const artistConnections = await getUserArtistConnections();
                setArtistConnections(artistConnections || []);
            } catch (error) {
                console.error('Error fetching user and artist connections:', error);
            }
        };

        fetchUserAndArtistConnections();
    }, []);

    if (artistConnections === null) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spinner />
            </div>
        );
    }

    const handleRemoveArtist = async (artistGuid: string) => {
        const updatedConnections = artistConnections.filter(connection => connection.artistGuid !== artistGuid);
        setArtistConnections(updatedConnections);

        try {
            await updateUserArtistConnections(updatedConnections);
        } catch (error) {
            console.error('Error updating user', error);
        }
    };

    const handleAddArtist = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let error = '';

        const newArtist: { artistName: string, artistGuid: string } = {
            artistName: newArtistName,
            artistGuid: '',
        };

        // Add the artist to the local state first
        const updatedArtistConnections = [...artistConnections, newArtist];
        setArtistConnections(updatedArtistConnections);
        setNewArtistName('');

        try {
            const artistDetails: BlendedArtistListItem[] = await getArtistDetails(newArtist.artistName);
            if (artistDetails.length === 1) {
                newArtist.artistGuid = artistDetails[0].artistId!;
                newArtist.artistName = artistDetails[0].artistName!;
                
                // Find the index of the new artist in the updatedArtistConnections array
                const index = updatedArtistConnections.findIndex(artist => artist.artistName.toLowerCase() === newArtist.artistName.toLowerCase());
                
                // Replace the new artist object with the updated one
                if (index !== -1) {
                    updatedArtistConnections[index] = newArtist;
                }
                
                await updateUserArtistConnections(updatedArtistConnections);
                const refreshedArtistConnections = await getUserArtistConnections();
                setArtistConnections(refreshedArtistConnections || []);

            } else if (artistDetails.length > 1) {
                error = 'Multiple details found';
                console.log(`Multiple details found for artist ${newArtist.artistName}. Unable to add artist.`);
                // Handle the case when multiple artists are found (e.g., show an error message)
            } else {
                error = 'No details found';
                console.log(`No details found for artist ${newArtist.artistName}. Unable to add artist.`);
                // Handle the case when no artist details are found (e.g., show an error message)
            }
            trackEvent(EventName.ARTIST_CONNECTION_MADE, {
            userId: userProfile!.userId,
            artistName: newArtist.artistName,
            ...(error !== '' && { errorMsg: error }),
            });
        } catch (error) {
            console.error('Error updating user', error);
        }
    };

    const handleContinue = async () => {
        if (!termsAgreed) {
            alert('Please agree to the terms and conditions before proceeding.');
            return;
        }

        try {
            await updateUserArtistConnections(artistConnections);
            history.push('/dashboard');
        } catch (error) {
            console.error('Error updating user', error);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen px-4 py-8">
            <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-md">
                <h2 className="font-nunito text-2xl md:text-3xl font-extrabold mb-6">Manage Artist Profiles</h2>
                <p className="text-base mb-8 font-nunito font-semibold">
                    Please connect all artist profiles you are associated with. This includes any solo projects, bands, or collaborations you are a part of.
                </p>
                <div className="mb-8">
                    <h3 className="font-nunito text-xl font-bold mb-4">Why Connect Artist Profiles?</h3>
                    <ul className="list-disc pl-6 space-y-2 font-nunito">
                        <li>Collect funds given by wearecoral members to support your artistic endeavors</li>
                        <li>Easy access to analytics on member contributions across all your projects</li>
                        <li>Ensure accurate representation of your artist profile on the platform</li>
                    </ul>
                </div>
                <div>
                    <h3 className="font-nunito text-xl font-bold mb-4">Steps to Connect Artist Profiles</h3>
                    <ol className="list-decimal pl-6 mb-6 space-y-2 font-nunito">
                        <li>Enter the name of an artist profile you are associated below and click &ldquo;Add&rdquo;</li>
                        <li>Repeat this process for each artist profile, including solo projects, bands, and collaborations</li>
                        <li>Review and agree to the <a href="/artist-terms" target="_blank" rel="noopener noreferrer" className="text-coral-600 hover:text-coral-800">Artist Terms and Conditions</a></li>
                        <li>Click &ldquo;Continue&rdquo; to finalise your artist profiles</li>
                    </ol>
                </div>
                <div className="mb-8">
                    <h3 className="font-nunito text-xl font-bold mb-4">Verification and Collecting Funds</h3>
                    <p className="text-base font-nunito mt-4">
                        Please note that contributions to artists must be paid out to the legal entity of the artist. For example, we cannot pay out funds for bands that exist as a legal entity to individual participants of the band. This distribution must be done by the legal entity of the band. This ensures that funds are passed to the rightful musicians and helps maintain transparency and accountability in the distribution of funds.
                    </p>
                    <p className="text-base font-nunito">
                        <br />
                        To ensure the integrity of the platform and protect artist rights, verification of connected artist profiles may be requested at the discretion of wearecoral.
                    </p>
                    <p className="text-base font-nunito">
                        <br />
                        Verification is mandatory when an artist wishes to collect funds through the platform. The verification process can be completed by the artist or their authorized representative. In such cases, you will be required to provide proof of identity and ownership of the artist profile. Our team will review and confirm the connection before any contributions are disbursed.
                    </p>
                </div>
                <table className="w-full mb-6">
                    <thead>
                        <tr>
                            <th className="text-left py-2">Artist Name</th>
                            <th className="text-right py-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {artistConnections.map((artist, index) => (
                            <tr key={index}>
                                <td className="py-2">{artist.artistName}</td>
                                <td className="text-right py-2">
                                    <button
                                        className="text-coral-600 hover:text-coral-800"
                                        onClick={() => handleRemoveArtist(artist.artistGuid)}
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex space-x-2 mb-6">
                    <input
                        type="text"
                        className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                        placeholder="Enter artist name"
                        value={newArtistName}
                        onChange={event => setNewArtistName(event.target.value)}
                    />
                    <CoralButton className="font-semibold text-xs sm:text-sm w-32" onClick={handleAddArtist}>
                        Add
                    </CoralButton>
                </div>
                <div className="mb-8">
                    <h3 className="font-nunito text-xl font-bold mb-4">Artist Terms and Conditions</h3>
                    <p className="text-base font-nunito">
                        By connecting your artist profiles and proceeding with the verification process, you agree to the <a href="/artist-terms" target="_blank" rel="noopener noreferrer" className="text-coral-pink">Artist Terms and Conditions</a>. These terms govern the use of the platform, the distribution of funds, and the responsibilities of both the artist and wearecoral. Please review them carefully before proceeding.
                    </p>
                    <div className="flex items-center mt-4">
                        <input
                            type="checkbox"
                            id="termsAgreed"
                            checked={termsAgreed}
                            onChange={event => setTermsAgreed(event.target.checked)}
                            className="mr-2"
                        />
                        <label htmlFor="termsAgreed" className="text-base font-nunito">
                            I have read and agree to the <a href="/artist-terms" target="_blank" rel="noopener noreferrer" className="text-coral-pink">Artist Terms and Conditions</a>
                        </label>
                    </div>
                </div>
                <div className="flex space-x-4">
                    <CoralButton className="font-semibold text-xs sm:text-sm w-full" onClick={handleContinue}>
                        Continue
                    </CoralButton>
                </div>
            </div>
        </div>
    );
};

export default ArtistConnections;