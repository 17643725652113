import React, { useEffect } from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-1.png';
import lightBlueWaveImage from '../../assets/images/light-blue-wave.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

const Trust: React.FC = () => {
    useEffect(() => {
        trackEvent(EventName.TRUST_PAGE_VIEW, {});
    }, []);
    

    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                {/* Page title */}
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        Trust allows us to cooperate.
                    </h1>
                </div>
            </div>
            
            {/* Hero image section */}
            <div className="2xl:hidden">
                <img src={aboutHeroImage} alt="Terms Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative">
                <img src={aboutHeroImage} alt="Terms Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            {/* Trust section */}
            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    {/* Left column for the header */}
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                TRUST BY DESIGN
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            How We Operate
                        </h2>
                    </div>
                    
                    {/* Right column for Trust content */}
                    <div className="md:w-1/2">
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            Trust is at the core of how we work together. We&apos;ve intentionally designed every aspect of our operations to prioritize transparency, security, and responsible handling of the voluntary contributions made by our users to support artists. Below, we&apos;ll provide a detailed look at how we&apos;ve designed trust into wearecoral.
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Corporate Structure
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            wearecoral operates as an Australian proprietary limited company (Pty Ltd). We operate multiple bank accounts that maintain a clear separation between the funds allocated for artists and the platform fees that constitute revenue for the company.
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            How We Handle Your Coral Payment
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            When you subscribe to wearecoral, your payment undergoes a transparent allocation process to ensure that funds are distributed safely and efficiently:
                        </p>
                        <ol className="list-decimal list-inside text-xs sm:text-sm md:text-base text-coral-black">
                            <li>Payment processing fees are deducted from the total payment amount.</li>
                            <li>The remaining funds are allocated as follows:
                                <ul className="list-disc list-inside ml-4">
                                    <li>10% (incl. GST) is transferred to the company revenue account to cover platform operations.</li>
                                    <li>The remainder constitutes the voluntary creative contribution.</li>
                                </ul>
                            </li>
                            <li>Voluntary creative contributions are securely managed to ensure they reach the intended artists:
                                <ul className="list-disc list-inside ml-4">
                                    <li>All contribution funds are held in a dedicated, segregated bank account, separated from the company&apos;s operational funds.</li>
                                    <li>At the end of each month, contributions for onboarded and verified artists are safely transferred directly to their nominated accounts.</li>
                                    <li>Funds for artists yet to be onboarded remain securely stored in the segregated account, safeguarded until the artist is successfully onboarded.</li>
                                </ul>
                            </li>
                        </ol>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            By maintaining separate bank accounts for artist funds and platform fees, wearecoral ensures a clear distinction between the money allocated for artists and the company&apos;s revenue.
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Artist Outreach and Distribution
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            As part of the service provided by the platform, the wearecoral team actively engages in outreach to inform artists of user contributions made to support their ongoing creative work. Artists who wish to receive these contributions can sign up to the platform, verify their identity and relationship to the artist profile, and securely receive the funds.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            In the event that an artist cannot be contacted, is unresponsive, or declines the contribution after a period of 9 months, the funds will be credited towards the user&apos;s future monthly subscription. If the user has closed their account, the funds will be donated to a music-related charity.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            By implementing these clear and transparent processes, wearecoral ensures that user contributions are handled responsibly and that artists receive the support they deserve.
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Terms and Conditions
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            Our terms and conditions clearly outline the agreement between wearecoral, artists, and users. They specify that:
                        </p>
                        <ul className="list-disc list-inside text-xs sm:text-sm md:text-base text-coral-black">
                            <li>User contributions are voluntary and do not create any employment, contractual, or agency relationship between the platform and the artists.</li>
                            <li>wearecoral acts as a facilitator, securely holding and distributing funds to the intended artists.</li>
                            <li>Artists are responsible for reporting the income and paying any necessary taxes.</li>
                        </ul>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            By clearly defining the roles and responsibilities of all parties involved, our terms and conditions help maintain transparency and trust in our operations.
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Further Information
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            For more information on our approach, please reach out to <a href="mailto:hello@wearecoral.org" className="text-coral-black underline">hello@wearecoral.org</a>.
                        </p>
                    </div>
                </div>
            </div>

            {/* Keep the blue wave image */}
            <div className="relative w-full h-full">
                <img src={lightBlueWaveImage} alt="Light Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-light-blue to-transparent"></div>
            </div>

            <div className="p-16 pt-0 bg-coral-light-blue">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                        <div className="w-full mb-4">
                            <h2 className="font-nunito text-2xl md:text-3xl font-extrabold pb-6 text-center">
                                A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                            </h2>
                        </div>
                        <JoinCoralButton />
                </div>
            </div>
        </div>
    );
};

export default Trust;