"use strict";
// eventDefinitions.ts
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventName = void 0;
var EventName;
(function (EventName) {
    EventName["HOME_PAGE_VIEW"] = "Home Page View";
    EventName["ABOUT_PAGE_VIEW"] = "About Page View";
    EventName["FOR_ARTIST_PAGE_VIEW"] = "For Artist Page View";
    EventName["TERMS_PAGE_VIEW"] = "Terms Page View";
    EventName["ARTIST_TERMS_PAGE_VIEW"] = "Artist Terms Page View";
    EventName["PRIVACY_PAGE_VIEW"] = "Privacy Page View";
    EventName["TRUST_PAGE_VIEW"] = "Trust Page View";
    EventName["CONTACT_PAGE_VIEW"] = "Contact Page View";
    EventName["PRINCIPLES_PAGE_VIEW"] = "Principles Page View";
    EventName["PUBLIC_SHARED_CORAL_VIEW"] = "Public Shared Coral View";
    EventName["PUBLIC_ARTIST_VIEW"] = "Public Artist View";
    EventName["VIEW_ALL_CONTRIBUTIONS_INTERACTION"] = "View All Contributions Interaction";
    EventName["FAQ_ITEM_VIEWED"] = "FAQ Item Viewed";
    EventName["COLLECTIVE_CALCULATOR_INTERACTION"] = "Collective Calculator Interaction";
    EventName["COLLECTIVE_TABLE_INTERACTION"] = "Collective Table Interaction";
    EventName["USER_ACCOUNT_CREATED"] = "User Account Created";
    EventName["USER_PROFILE_SETUP"] = "User Profile Setup";
    EventName["DASHBOARD_PAGE_VIEW"] = "Dashboard Page View";
    EventName["OUR_IMPACT_PAGE_VIEW"] = "Our Impact Page View";
    EventName["ARTIST_PROFILE_GENERATION"] = "Artist Profile Generation";
    EventName["ARTIST_CONNECTION_MADE"] = "Artist Connection Made";
    EventName["ARTIST_VERIFICATION_PAGE_VIEW"] = "Artist Verification Page View";
    EventName["ARTIST_VERIFICATION_SUBMITTED"] = "Artist Verification Submitted";
    EventName["IDENTIFICATION_PAGE_VIEW"] = "Identification Page View";
    EventName["IDENTIFICATION_SUBMITTED"] = "Identification Submitted";
    EventName["CREATE_CORAL_STARTED"] = "Create Coral Started";
    EventName["CORAL_CREATION_METHOD"] = "Coral Creation Method";
    EventName["CREATE_CORAL_SAVED"] = "Create Coral Saved";
    EventName["CREATE_CORAL_GOTO_PAYMENT"] = "Create Coral Goto Payment";
    EventName["CREATE_CORAL_COMPLETED"] = "Create Coral Completed";
    EventName["ADD_ARTIST_TO_CORAL"] = "Add Artist to Coral";
    EventName["REMOVE_ARTIST_FROM_CORAL"] = "Remove Artist from Coral";
    EventName["SIMULATE_CORAL"] = "Simulate Coral";
    EventName["BOOST_ARTIST_IN_CORAL"] = "Boost Artist in Coral";
    EventName["UPDATE_CORAL_NAME"] = "Update Coral Name";
    EventName["SHARE_CORAL"] = "Share Coral";
    // Add more events as needed
})(EventName = exports.EventName || (exports.EventName = {}));
